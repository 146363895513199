<template>
  <div class="service-list">
    <AccordionItem shouldOpen :title="`Services (${filteredServices.length})`">
      <FilterList type="service" :tags="filteredServices" />
    </AccordionItem>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';
import FilterList from './FilterList';
import AccordionItem from './accordion/AccordionItem';

export default {
  components: {
    FilterList,
    AccordionItem,
  },
  props: {
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      services: [],
    };
  },
  computed: {
    ...mapState(['selected_business_unit']),
    filteredServices() {
      return this.services.filter((service) => {
        const businessUnitOK =
          this.selected_business_unit === '' ||
          service.businessUnits.some(
            (bu) => bu.id === this.selected_business_unit
          );
        return (
          businessUnitOK &&
          service.name.toLowerCase().includes(this.query.toLowerCase())
        );
      });
    },
  },
  apollo: {
    services: {
      query: gql`
        query {
          services {
            id
            name
            businessUnits {
              id
            }
          }
        }
      `,
      update({ services }) {
        return this.sortArrayCaseInsensitive(services);
      },
    },
  },
};
</script>
