<template>
  <Card classes="full-width glance">
    <div class="name">
      <h1>{{ product.name }}</h1>
      <Bar />
    </div>
    <MarkDown :source="product.description" />
    <div class="alias category" v-if="product.aliases.length > 0">
      <div class="label">Aliases</div>
      <div class="value">{{ aliasesList(product.aliases) }}</div>
    </div>
    <TagsContainerWithLabel
      v-if="product.teams.length > 0"
      label="Team"
      pluralLabel="Teams"
      :tags="product.teams"
      type="team"
      route="Team"
    />
    <TagsContainerWithLabel
      v-if="product.productowner.length > 0"
      label="Product owner"
      pluralLabel="Product owners"
      :tags="product.productowner"
      type="person"
      route="Employee"
    />
    <TagsContainerWithLabel
      label="Business unit"
      pluralLabel="Business units"
      :tags="product.businessUnits"
      type="business-unit"
    />
    <Links :links="product.links" />
    <div class="button-group">
      <button class="default button" @click="edit(product.id)">Edit</button>
      <BackButton title="OK" classes="submit" />
    </div>
  </Card>
</template>

<script>
import gql from 'graphql-tag';
import TagsContainerWithLabel from '../basic/tags/TagsContainerWithLabel';
import Card from '../basic/Card';
import BackButton from '../basic/BackButton';
import Bar from '../basic/Bar';
import Links from '../basic/Links';
import MarkDown from '../basic/MarkDown';

export default {
  name: 'ProductPage',
  components: {
    Card,
    BackButton,
    Bar,
    Links,
    TagsContainerWithLabel,
    MarkDown,
  },
  data() {
    return {
      product: {
        name: '',
        description: '',
        aliases: [],
        teams: [],
        productowner: [],
        businessUnits: [],
        links: [],
      },
    };
  },
  methods: {
    aliasesList(aliases) {
      return aliases.join(', ');
    },
    edit(id) {
      this.$router.push({ name: 'Product Edit', params: { id } });
    },
  },
  apollo: {
    product: {
      query: gql`
        query SearchProduct($productId: ID!) {
          products(where: { id: $productId }) {
            id
            name
            description
            aliases
            teams {
              id
              name
            }
            productowner {
              id
              name
            }
            businessUnits {
              id
              name
            }
            links {
              id
              displayname
              url
              type
            }
          }
        }
      `,
      variables() {
        return {
          productId: this.$route.params.id,
        };
      },
      update(data) {
        return data.products[0];
      },
    },
  },
};
</script>
