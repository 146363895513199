<template>
  <div class="container">
    <Card classes="container left">
      <h1>Create a team</h1>
      <TeamForm v-bind.sync="team" @submitForm="submit" ref="teamForm">
        <div slot="buttonGroup" class="button-group">
          <button
            class="button default"
            type="button"
            id="cancelButton"
            v-on:click="resetInterface()"
          >
            Cancel
          </button>
          <button type="submit" class="submit button" :disabled="submitting">
            Create
          </button>
        </div>
      </TeamForm>
    </Card>
    <Card class="container right">
      <h1>Edit a team</h1>
      <TeamList />
    </Card>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import TeamList from './TeamList';
import TeamForm from './TeamForm';
import Card from '../../basic/Card';
import { v4 as uuidv4 } from 'uuid';
import linksCrudMixin from '@/utils/linksCrudMixin';

const emptyTeam = {
  name: '',
  description: '',
  parentTeam: null,
  members: [],
  products: [],
  links: [],
  businessUnits: [],
};

export default {
  components: {
    TeamList,
    TeamForm,
    Card,
  },
  mixins: [linksCrudMixin],
  data() {
    return {
      team: { ...emptyTeam },
      submitting: false,
    };
  },
  methods: {
    resetInterface() {
      Object.assign(this.team, emptyTeam);
      this.$nextTick(() => {
        this.$refs.teamForm.$refs.form?.reset();
      });
    },
    async createTeam() {
      const createdLinks = await this.createOrUpdateLinks(this.team.links);
      const data = {
        team: {
          id: uuidv4(),
          name: this.team.name,
          description: this.team.description,
        },
        relations: {
          memberIds: this.team.members.map((entry) => entry.id),
          productIds: this.team.products.map((entry) => entry.id),
          businessUnitIds: this.team.businessUnits.map((entry) => entry.id),
          linkIds: createdLinks,
          parentIds: this.team.parentTeam ? [this.team.parentTeam.id] : [],
        },
      };
      await this.$apollo.mutate({
        mutation: gql`
          mutation($data: CreateAndUpdateTeamInput!) {
            CreateAndUpdateTeam(data: $data) {
              id
            }
          }
        `,
        variables: {
          data,
        },
      });
      await this.$apollo.getClient().resetStore();
      this.$toasted.global.update_toaster({
        message: `You have successfully created ${this.team.name}`,
      });
    },
    async submit() {
      this.submitting = true;
      try {
        await this.createTeam();
        this.resetInterface();
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin-top: 0;
}
</style>
