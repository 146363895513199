<template>
  <div class="team-list">
    <AccordionItem shouldOpen :title="`Teams (${filteredTeams.length})`">
      <FilterList type="team" :tags="filteredTeams" />
    </AccordionItem>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';
import FilterList from './FilterList';
import AccordionItem from './accordion/AccordionItem';

export default {
  components: {
    FilterList,
    AccordionItem,
  },
  props: {
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      teams: [],
    };
  },
  computed: {
    ...mapState(['selected_business_unit']),
    filteredTeams() {
      return this.teams.filter((team) => {
        const businessUnitOK =
          this.selected_business_unit === '' ||
          team.businessUnits.some(
            (bu) => bu.id === this.selected_business_unit
          );
        return (
          businessUnitOK &&
          team.name.toLowerCase().includes(this.query.toLowerCase())
        );
      });
    },
  },
  apollo: {
    teams: {
      query: gql`
        query {
          teams {
            id
            name
            businessUnits {
              id
            }
          }
        }
      `,
      update({ teams }) {
        return this.sortArrayCaseInsensitive(teams);
      },
    },
  },
};
</script>
