<template>
  <div class="container">
    <Modal
      v-if="confirmModal"
      :name="room.name"
      @confirm="deleteRoom"
      @cancel="cancelDelete"
    />
    <Card>
      <h1>Edit {{ room.name }}</h1>
      <RoomForm v-bind.sync="room" @submitForm="submit">
        <div slot="buttonGroup" class="button-group">
          <button class="button default" type="button" v-on:click="cancel()">
            Cancel
          </button>
          <button
            class="delete button"
            type="button"
            v-on:click="confirmDelete()"
          >
            Delete
          </button>
          <button class="submit button" type="submit">Save</button>
        </div>
      </RoomForm>
    </Card>
  </div>
</template>

<script>
import RoomForm from './RoomForm';
import Modal from '../../basic/Modal';
import Card from '../../basic/Card';
import gql from 'graphql-tag';
import linksCrudMixin from '@/utils/linksCrudMixin';
export default {
  components: {
    Modal,
    Card,
    RoomForm,
  },
  mixins: [linksCrudMixin],
  data() {
    return {
      room: {
        name: '',
        code: '',
        location: '',
        outlookname: '',
        aliases: [],
        capacity: 0,
        zoom: false,
        byod: false,
        wheelchair: false,
        businessUnits: [],
      },
      confirmModal: false,
    };
  },
  apollo: {
    room: {
      query: gql`
        query($id: ID!) {
          rooms(where: { id: $id }) {
            id
            name
            code
            location
            outlookname
            aliases
            zoom
            byod
            wheelchair
            capacity
            whiteboard
            workplace
            businessUnits {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      update(data) {
        return data.rooms[0];
      },
    },
  },
  methods: {
    confirmDelete() {
      this.confirmModal = true;
    },
    cancelDelete() {
      this.confirmModal = false;
    },
    async deleteRoom() {
      const name = this.room.name;
      await this.$apollo.mutate({
        mutation: gql`
          mutation($id: ID!) {
            deleteRooms(where: { id: $id }) {
              nodesDeleted
            }
          }
        `,
        variables: {
          id: this.room.id,
        },
      });
      await this.$apollo.getClient().clearStore();
      this.$toasted.global.update_toaster({
        message: `You have successfully removed ${name}`,
      });
      this.$router.replace({ name: 'Rooms' });
    },
    async updateRoom() {
      const data = {
        room: {
          id: this.room.id,
          name: this.room.name,
          code: this.room.code,
          aliases: this.room.aliases,
          capacity: this.room.capacity,
          zoom: this.room.zoom,
          byod: this.room.byod,
          wheelchair: this.room.wheelchair,
          whiteboard: this.room.whiteboard,
          workplace: this.room.workplace,
        },
        relations: {
          businessUnitIds: this.room.businessUnits.map((entry) => entry.id),
        },
      };
      await this.$apollo.mutate({
        mutation: gql`
          mutation($data: CreateAndUpdateRoomInput!) {
            CreateAndUpdateRoom(data: $data) {
              id
            }
          }
        `,
        variables: {
          data,
        },
      });
      await this.$apollo.getClient().clearStore();
      this.$toasted.global.update_toaster({
        message: `You have successfully updated ${this.room.name}`,
      });
    },
    async submit() {
      await this.updateRoom();
      this.$router.back();
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin-top: 0;
}
</style>
