<template>
  <div class="tags-container">
    <Pluralize :singular="label" :multiple="pluralLabel" :count="tags.length" />
    <TagsContainer :tags="tags" :type="type" :route="route" />
  </div>
</template>

<script>
import TagsContainer from './TagsContainer';
import Pluralize from '../Pluralize';

export default {
  components: {
    TagsContainer,
    Pluralize,
  },
  props: {
    label: String,
    pluralLabel: String,
    tags: Array,
    type: String,
    route: String,
  },
};
</script>

<style lang="scss" scoped>
.tags-container {
  margin: 20px 0 0 0;
  span {
    font-style: italic;
  }
  .tags {
    margin-top: 0px;
  }
}
</style>
