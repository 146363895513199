<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="pencil"
      d="M12.6687 4.29703L13.8621 3.09571C14.0457 2.91089 14.0457 2.63366 13.8621 2.44884L11.567 0.138614C11.3834 -0.0462046 11.108 -0.0462046 10.9244 0.138614L9.73096 1.33993L12.6687 4.29703Z"
      :fill="color"
    />
    <path
      class="pencil"
      d="M9.08852 1.89438L0.183607 10.8581C0.0918033 10.9505 0 11.1353 0 11.2277V13.5379C0 13.8152 0.183607 14 0.459016 14H2.7541C2.8459 14 3.02951 13.9076 3.12131 13.8152L12.0262 4.85147L9.08852 1.89438Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'Pencil',
  props: {
    color: {
      default: 'white',
      type: String,
    },
  },
};
</script>
