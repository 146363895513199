<template>
  <div>
    <SearchableTagsContainer
      :tags="filteredTeams"
      type="team"
      :route="'Team Edit'"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';
import SearchableTagsContainer from '../../basic/tags/SearchableTagsContainer';

export default {
  components: {
    SearchableTagsContainer,
  },
  data() {
    return {
      teams: [],
    };
  },
  computed: {
    ...mapState(['selected_business_unit']),
    filteredTeams() {
      return this.teams.filter((team) => {
        return (
          this.selected_business_unit === '' ||
          team.businessUnits.some((bu) => this.selected_business_unit === bu.id)
        );
      });
    },
  },
  apollo: {
    teams: {
      query: gql`
        query {
          teams {
            id
            name
            businessUnits {
              id
            }
          }
        }
      `,
      update({ teams }) {
        return this.sortArrayCaseInsensitive(teams);
      },
    },
  },
};
</script>
