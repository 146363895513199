<template>
  <div class="accordion-item">
    <button class="accordion-title" @click="toggleTab">
      <div>
        <h1>{{ title }}</h1>
      </div>
      <div class="arrow" :class="{ rotated: isOpened }">
        <Arrow color="#023A4F" />
      </div>
    </button>
    <TransitionExpand>
      <div class="accordion-content" v-if="isOpened">
        <slot></slot>
      </div>
    </TransitionExpand>
  </div>
</template>
<script>
import Arrow from '../../../basic/icons/Arrow';
import TransitionExpand from './TransitionExpand';

export default {
  components: {
    Arrow,
    TransitionExpand,
  },
  props: {
    shouldOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpened: this.shouldOpen,
    };
  },
  methods: {
    toggleTab() {
      this.isOpened = !this.isOpened;
    },
  },
};
</script>

<style scoped lang="scss">
.accordion-item {
  border-bottom: 1px solid #f4f4f4;
  width: 100%;
}
.arrow {
  display: flex;
  align-items: center;
  height: 100%;
  transition: all 0.4s;
}
.accordion-title {
  display: flex;
  justify-content: space-between;
  height: 50px;
  cursor: pointer;
  min-width: 100%;
  h1 {
    font-size: $font-size-default;
    margin: 0;
    line-height: 50px;
  }
}
.rotated {
  transform: rotate(180deg);
}
</style>
