<template>
  <div class="container">
    <Card classes="container left">
      <h1>Create a room</h1>
      <RoomForm v-bind.sync="room" @submitForm="submit" ref="roomForm">
        <div slot="buttonGroup" class="button-group">
          <button
            class="button default"
            type="button"
            id="cancelButton"
            v-on:click="resetInterface()"
          >
            Cancel
          </button>
          <button type="submit" class="submit button" :disabled="submitting">
            Create
          </button>
        </div>
      </RoomForm>
    </Card>
    <Card classes="container right">
      <h1>Edit a room</h1>
      <RoomTagList />
    </Card>
  </div>
</template>

<script>
import RoomTagList from './RoomTagList';
import RoomForm from './RoomForm';
import Card from '../../basic/Card';
import { v4 as uuidv4 } from 'uuid';
import gql from 'graphql-tag';

const emptyRoom = {
  name: '',
  code: '',
  outlookname: '',
  location: '',
  aliases: [],
  capacity: 0,
  zoom: false,
  byod: false,
  wheelchair: false,
  whiteboard: false,
  workplace: false,
  businessUnits: [],
};

export default {
  components: {
    RoomTagList,
    RoomForm,
    Card,
  },
  data() {
    return {
      room: { ...emptyRoom },
      submitting: false,
    };
  },
  methods: {
    resetInterface() {
      Object.assign(this.room, emptyRoom);
      this.$nextTick(() => {
        this.$refs.roomForm.$refs.form?.reset();
      });
    },
    async createRoom() {
      const data = {
        room: {
          id: uuidv4(),
          name: this.room.name,
          location: this.room.location,
          code: this.room.code,
          outlookname: this.room.outlookname,
          aliases: this.room.aliases,
          capacity: this.room.capacity,
          zoom: this.room.zoom,
          byod: this.room.byod,
          wheelchair: this.room.wheelchair,
          whiteboard: this.room.whiteboard,
          workplace: this.room.workplace,
        },
        relations: {
          businessUnitIds: this.room.businessUnits.map((entry) => entry.id),
        },
      };
      await this.$apollo.mutate({
        mutation: gql`
          mutation($data: CreateAndUpdateRoomInput!) {
            CreateAndUpdateRoom(data: $data) {
              id
            }
          }
        `,
        variables: {
          data,
        },
      });
      this.$toasted.global.update_toaster({
        message: `You have successfully created ${this.room.name}`,
      });
      await this.$apollo.getClient().resetStore();
      this.resetInterface();
    },
    async submit() {
      this.submitting = true;
      try {
        await this.createRoom();
        this.resetInterface();
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin-top: 0;
}
</style>
