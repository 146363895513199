<template>
  <div class="container">
    <Card classes="container left">
      <h1>Create an employee</h1>
      <EmployeeForm
        v-bind.sync="employee"
        @submitForm="submit"
        ref="employeeForm"
      >
        <div slot="buttonGroup" class="button-group">
          <button
            class="button default"
            type="button"
            id="cancelButton"
            v-on:click="resetInterface()"
          >
            Cancel
          </button>
          <button type="submit" class="submit button" :disabled="submitting">
            Create
          </button>
        </div>
      </EmployeeForm>
    </Card>
    <Card classes="container right">
      <h1>Edit an employee</h1>
      <EmployeeList />
    </Card>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import EmployeeList from './EmployeeList';
import { v4 as uuidv4 } from 'uuid';
import EmployeeForm from './EmployeeForm';
import Card from '../../basic/Card';

const emptyEmployee = {
  name: '',
  mail: '',
  githubUserName: '',
  teams: [],
  skills: [],
  productowner: [],
  services: [],
  businessUnits: [],
};

export default {
  components: {
    EmployeeList,
    EmployeeForm,
    Card,
  },
  data() {
    return {
      employee: { ...emptyEmployee },
      submitting: false,
    };
  },
  methods: {
    resetInterface() {
      Object.assign(this.employee, emptyEmployee);
      this.$nextTick(() => {
        this.$refs.employeeForm.$refs.form?.reset();
      });
    },
    async createEmployee() {
      const data = {
        employee: {
          id: uuidv4(),
          name: this.employee.name,
          mail: this.employee.mail,
          githubUserName: this.employee.githubUserName,
        },
        relations: {
          teamIds: this.employee.teams.map((team) => team.id),
          skillIds: this.employee.skills.map((entry) => entry.id),
          serviceIds: this.employee.services.map((entry) => entry.id),
          productIds: this.employee.productowner.map((entry) => entry.id),
          businessUnitIds: this.employee.businessUnits.map((entry) => entry.id),
        },
      };
      await this.$apollo.mutate({
        mutation: gql`
          mutation($data: CreateAndUpdateEmployeeInput!) {
            CreateAndUpdateEmployee(data: $data) {
              id
            }
          }
        `,
        variables: {
          data,
        },
      });
      this.$toasted.global.update_toaster({
        message: `You have successfully created ${this.employee.name}`,
      });
      await this.$apollo.getClient().resetStore();
    },
    async submit() {
      this.submitting = true;
      try {
        await this.createEmployee();
        this.resetInterface();
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin-top: 0;
}
</style>
