<template>
  <div class="modal">
    <div class="modal-window">
      <p>Are you sure you want to delete {{ name }}?</p>
      <div class="actions">
        <button class="button delete" @click="onCancel">Cancel</button>
        <button class="button submit" @click="onConfirm">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    name: String,
  },
  methods: {
    onConfirm() {
      this.$emit('confirm');
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: $modal-index;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-window {
  min-width: 400px;
  max-width: 600px;
  background: $card-background;
  box-shadow: $box-shadow-card;
  border-radius: $radius-large;
  padding: $card-padding;
}

.modal-window .actions {
  display: flex;
  justify-content: flex-end;
}
</style>
