<template>
  <div class="card" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    classes: String,
  },
};
</script>
<style lang="scss" scoped>
.card {
  background: $card-background;
  box-shadow: $box-shadow-card;
  border-radius: $radius-large;
  padding: $card-padding;
  margin-left: calc(50% - 300px);
  width: 600px;
  margin-top: 50px;
  &.container {
    display: inline-block;
    width: calc(50% - #{$menu-height});
  }
  &.left {
    vertical-align: top;
    margin: 40px 20px 40px 40px;
  }
  &.right {
    margin: 40px 40px 40px 20px;
  }
  &.list-item {
    width: inherit;
    min-height: 150px;
    padding: 20px 20px 20px 60px;
    margin: 0;
    .header {
      display: flex;
      justify-content: space-between;
      .name {
        margin-top: 0;
        display: inline-block;
        font-size: $font-size-large;
      }
    }
  }
  &.glance {
    .bar {
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }
  .category {
    margin-top: 20px;
    .label {
      font-style: italic;
      display: block;
      width: 160px;
    }
    .value {
      display: inline-block;
    }
  }
}
@media (max-width: $extra-small) {
  .card {
    width: 100%;
    margin: 0;
    min-height: calc(100vh - #{$menu-height});
    &.full-width {
      border-radius: 0;
    }
  }
}
</style>
