<template>
  <div class="search-icon icon">
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.47487 7.47487C8.84171 6.10804 8.84171 3.89196 7.47487 2.52513C6.10804 1.15829 3.89196 1.15829 2.52513 2.52513C1.15829 3.89196 1.15829 6.10804 2.52513 7.47487C3.89196 8.84171 6.10804 8.84171 7.47487 7.47487ZM8.53553 8.53553C10.4882 6.58291 10.4882 3.41709 8.53553 1.46447C6.58291 -0.488155 3.41709 -0.488155 1.46447 1.46447C-0.488155 3.41709 -0.488155 6.58291 1.46447 8.53553C3.41709 10.4882 6.58291 10.4882 8.53553 8.53553Z"
        fill="#023A4F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7782 12.7782C12.4853 13.0711 12.0104 13.0711 11.7175 12.7782L7.2981 8.35876L8.35876 7.2981L12.7782 11.7175C13.0711 12.0104 13.0711 12.4853 12.7782 12.7782V12.7782Z"
        fill="#023A4F"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SearchIcon',
};
</script>
