<template>
  <div class="rooms">
    <div class="scroll-container">
      <div class="search-bar-header">
        <SearchBar v-model="query" />
      </div>
      <CardGrid>
        <Card classes="list-item" v-for="room of currentRooms" :key="room.id">
          <div class="header">
            <div class="location-info">
              <h1 class="name">{{ room.code }} {{ room.name }}</h1>
              <TagsContainer :tags="room.businessUnits" type="business-unit" />
            </div>
            <router-link :to="{ name: 'Room', params: { id: room.id } }">
              <More color="#b4c4cb" />
            </router-link>
          </div>
          <Bar />
          <div class="content">
            <div class="alias category" v-if="room.aliases">
              <i>Aliases: </i>{{ aliasesList(room.aliases) }}
            </div>
            <div v-if="room.capacity" class="category">
              Capacity {{ room.capacity }}
              <span v-if="room.workplace">workplaces</span>
            </div>
            <div class="category tools">
              <div class="tool-info" v-if="room.zoom">
                <img
                  src="@/assets/LogoZoom.png"
                  alt="Zoom logo"
                  title="Zoom available"
                />
                <p>Zoom available</p>
              </div>
              <div class="tool-info" v-if="room.byod">
                <img
                  src="@/assets/screen.svg"
                  title="HDMI + screen available"
                />
                <p>HDMI + screen available</p>
              </div>
              <div class="tool-info" v-if="room.wheelchair">
                <img
                  class="wheelchair"
                  src="@/assets/wheelchair.svg"
                  title="Wheelchair accessible"
                />
                <p>Wheelchair accessible</p>
              </div>
              <div class="tool-info" v-if="room.whiteboard">
                <img
                  class="whiteboard"
                  src="@/assets/whiteboard.svg"
                  title="Whiteboard available"
                />
                <p>Whiteboard available</p>
              </div>
            </div>
          </div>
        </Card>
        <h2 v-if="currentRooms.length == 0">
          There are no rooms that match the requirements, please change your
          filters
        </h2>
      </CardGrid>
    </div>
    <aside class="side-bar" :class="{ active: isActive }">
      <button class="toggle-button" @click="toggleNav()">
        Filters ({{ amountFilters }})
      </button>
      <div class="content">
        <div class="filters">
          <NumberInputWithLabel
            name="capacity"
            label="Capacity"
            v-model="capacityFilter"
          />
          <CheckBoxWithLabel name="zoom" label="Zoom" v-model="zoomFilter" />
          <CheckBoxWithLabel name="byod" label="Screen" v-model="byodFilter" />
          <CheckBoxWithLabel
            name="wheelchair"
            label="Wheelchair accessible"
            v-model="wheelchairFilter"
          />
          <CheckBoxWithLabel
            name="whiteboard"
            label="Whiteboard"
            v-model="whiteboardFilter"
          />
          <CheckBoxWithLabel
            name="workplace"
            label="Workplaces with monitor"
            v-model="workplaceFilter"
          />

          <button class="button submit" @click="clearFilters">
            <svg
              viewBox="0 0 22 22"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:svg="http://www.w3.org/2000/svg"
            >
              <path
                d="m370.22 459.3c10.2 11.1 15.8 25.6 15.8 40.6v442c0 26.601 32.1 40.1 51.1 21.4l123.3-141.3c16.5-19.8 25.6-29.601 25.6-49.2v-272.8c0-15 5.7-29.5 15.8-40.601l353.8-383.9c26.5-28.8 6.101-75.5-33.1-75.5h-873c-39.2 0-59.7 46.6-33.1 75.5l353.8 383.8"
                transform="matrix(.01646 0 0 .01646 3 2.998)"
                fill="#4d4d4d"
                id="path2"
                style="fill: #ffffff"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="m 10.481324,10.257894 c 0.208957,-0.208959 0.547746,-0.208959 0.756701,0 l 6.070789,6.07081 c 0.208962,0.208955 0.208962,0.547761 0,0.756723 v 0 c -0.208934,0.208963 -0.547726,0.208963 -0.756681,0 l -6.070809,-6.070831 c -0.208958,-0.208955 -0.208957,-0.547747 0,-0.756702 z"
                fill="#4d4d4d"
                id="path4-0"
                style="
                  stroke-width: 3.61299977;
                  stroke-dasharray: none;
                  fill: #ff0000;
                "
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="m 17.309028,10.257901 c 0.208962,0.208955 0.208962,0.54774 0,0.756702 l -6.07081,6.070824 c -0.208956,0.208963 -0.547742,0.208963 -0.7567,0 v 0 c -0.208957,-0.208962 -0.208957,-0.547768 0,-0.756716 l 6.070808,-6.07081 c 0.208962,-0.20896 0.547747,-0.20896 0.756702,0 z"
                fill="#4d4d4d"
                id="path6-2"
                style="
                  stroke-width: 3.813;
                  stroke-dasharray: none;
                  fill: #ff0000;
                "
              />
            </svg>
            clear all filters
          </button>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import Bar from '../basic/Bar';
import More from '../basic/icons/More';
import Card from '../basic/Card';
import CardGrid from '../basic/CardGrid';
import SearchBar from '../basic/searchbar/SearchBar';
import TagsContainer from '../basic/tags/TagsContainer';
import CheckBoxWithLabel from '../basic/formfields/CheckBoxWithLabel';
import NumberInputWithLabel from '../basic/formfields/NumberInputWithLabel';
import { mapState } from 'vuex';

export default {
  name: 'rooms',

  components: {
    More,
    Card,
    Bar,
    SearchBar,
    CardGrid,
    TagsContainer,
    CheckBoxWithLabel,
    NumberInputWithLabel,
  },
  data() {
    return {
      rooms: [],
      query: '',
      zoomFilter: false,
      byodFilter: false,
      wheelchairFilter: false,
      whiteboardFilter: false,
      workplaceFilter: false,
      capacityFilter: 0,
      isActive: false,
    };
  },
  computed: {
    ...mapState(['selected_business_unit']),
    currentRooms() {
      return this.rooms.filter((room) => {
        return (
          (this.selected_business_unit === '' ||
            room.businessUnits.some(
              (bu) => this.selected_business_unit === bu.id
            )) &&
          (room.name.toLowerCase().includes(this.query.toLowerCase()) ||
            room.aliases?.some((alias) =>
              alias.toLowerCase().includes(this.query.toLowerCase())
            ) ||
            room.code.toLowerCase().includes(this.query.toLowerCase()) ||
            room.outlookname
              .toLowerCase()
              .includes(this.query.toLowerCase())) &&
          room.capacity >= this.capacityFilter &&
          (this.zoomFilter == false || this.zoomFilter == room.zoom) &&
          (this.byodFilter == false || this.byodFilter == room.byod) &&
          (this.wheelchairFilter == false ||
            this.wheelchairFilter == room.wheelchair) &&
          (this.whiteboardFilter == false ||
            this.whiteboardFilter == room.whiteboard) &&
          (this.workplaceFilter == false ||
            this.workplaceFilter == room.workplace)
        );
      });
    },
    amountFilters() {
      return (
        this.zoomFilter +
        this.byodFilter +
        this.wheelchairFilter +
        this.whiteboardFilter +
        this.workplaceFilter +
        (this.capacityFilter > 0)
      );
    },
  },

  apollo: {
    rooms: {
      query: gql`
        query {
          rooms {
            id
            name
            code
            outlookname
            aliases
            zoom
            byod
            wheelchair
            capacity
            whiteboard
            workplace
            businessUnits {
              id
              name
            }
          }
        }
      `,
      update({ rooms }) {
        return rooms === undefined
          ? undefined
          : [...rooms].sort(function (a, b) {
              return a.code.localeCompare(b.code, 'en', {
                sensitivity: 'base',
                numeric: true,
              });
            });
      },
    },
  },
  methods: {
    aliasesList(aliases) {
      if (aliases != null && aliases.length > 0) {
        return aliases.join(', ');
      }
      return '-';
    },
    clearFilters() {
      this.zoomFilter = false;
      this.byodFilter = false;
      this.wheelchairFilter = false;
      this.whiteboardFilter = false;
      this.workplaceFilter = false;
      this.capacityFilter = 0;
    },
    toggleNav() {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.rooms {
  display: flex;
  .scroll-container {
    flex-grow: 1; /* this makes sure the container grows after collapsing the sidebar */
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }
}
.location-info {
  display: flex;
  gap: 15px;
  .tags {
    margin: 0;
    margin-bottom: 0.67em;
    &::v-deep .tag {
      margin: 0;
    }
  }
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
  .container {
    margin-bottom: 0;
    align-items: center;
    &::v-deep label {
      width: auto;
      white-space: nowrap;
    }
    &::v-deep input[name='capacity'] {
      width: 60px;
    }
  }
}
.tools {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  .tool-info {
    display: flex;
    p {
      display: flex;
    }
  }
}

img {
  height: 45px;
  margin-right: 10px;
  border-radius: 16px;
  background-color: $heritage-blue;
}

svg {
  height: 30px;
}

button {
  display: flex;
  align-items: center;
  padding: 10px;
}
.side-bar {
  height: calc(100vh - #{$menu-height});
  background: #fff;
  z-index: $sidebar-index;
  position: sticky;
  top: $menu-height;
  .content {
    display: none;
    overflow-y: auto;
    max-height: 100%;
  }
  .toggle-button {
    cursor: pointer;
    position: absolute;
    right: 0;
    transform: rotate(-90deg) translateX(20px);
    transform-origin: bottom right;
    padding: 8px;
    border-radius: $radius-small $radius-small 0 0;
    box-shadow: $box-shadow-card;
    color: white;
    background-color: $spark-orange;
    border: none;
    white-space: nowrap;
    margin: 0;
    font-size: $font-size-small;
  }
}
.side-bar.active {
  display: block;
  width: 20%;
  min-width: 275px;
  flex-grow: 1;
  .toggle-button {
    right: 100%;
  }
  .content {
    display: block;
    padding: 10px;
  }
}
@media (max-width: $extra-small) {
  .side-bar {
    right: 0;
  }
}
</style>
