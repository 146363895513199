<template>
  <div>
    <SearchableTagsContainer
      :tags="skills"
      type="skill"
      :route="'Skill Edit'"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import SearchableTagsContainer from '../../basic/tags/SearchableTagsContainer';

export default {
  name: 'SkillList',
  components: {
    SearchableTagsContainer,
  },
  data() {
    return {
      skills: [],
    };
  },
  apollo: {
    skills: {
      query: gql`
        query {
          skills {
            id
            name
          }
        }
      `,
      update({ skills }) {
        return this.sortArrayCaseInsensitive(skills);
      },
    },
  },
};
</script>
