<template>
  <div class="links category">
    <div class="link" v-for="(link, index) in links" :key="index">
      <a :href="link.url" target="_blank">
        <LinkIconGenerator :type="link.type" />
        <span>{{ link.displayname }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import LinkIconGenerator from '../basic/LinkIconGenerator';

export default {
  name: 'Links',
  components: {
    LinkIconGenerator,
  },
  props: {
    links: Array,
  },
};
</script>
<style scoped lang="scss">
.link {
  width: 50%;
  margin-bottom: 15px;
  a {
    text-decoration: none;
    color: $heritage-blue;
    display: flex;
    align-items: center;
  }
  span {
    margin-left: 15px;
  }
}
</style>
