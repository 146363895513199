<template>
  <div class="flex-form">
    <label><slot></slot></label>
    <multiselect
      class="multi-select team"
      ref="teamselector"
      :loading="loading()"
      :multiple="true"
      :taggable="false"
      :hide-selected="true"
      :value="value"
      label="name"
      track-by="id"
      :options="options"
      placeholder="Select a team"
      @input="handleInput"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'TeamSelector',
  props: {
    value: {
      type: Array,
      default: () => [{}],
    },
  },
  computed: {
    sortedValue() {
      return this.sortArrayCaseInsensitive(this.value);
    },
  },
  data() {
    return {
      loadingTasks: 0,
      options: [],
    };
  },
  methods: {
    handleInput(input) {
      this.$emit('input', input);
    },
    loading() {
      return this.loadingTasks !== 0;
    },
    async loadTask(task) {
      this.loadingTasks++;
      try {
        const result = await task();
        return result;
      } finally {
        this.loadingTasks--;
      }
    },
  },
  apollo: {
    options: {
      query: gql`
        query {
          teams {
            id
            name
          }
        }
      `,
      update({ teams }) {
        return this.sortArrayCaseInsensitive(teams);
      },
    },
  },
};
</script>
