<template>
  <a class="tag" :class="[{ active: isActive }, type]" :href="href">
    <div class="title">{{ title }}</div>
    <div v-if="isClosable" class="close-icon"><Close /></div>
  </a>
</template>

<script>
import Close from '../icons/Close';

export default {
  name: 'Tag',
  components: {
    Close,
  },
  props: {
    title: String,
    type: String,
    href: String,
    isActive: {
      type: Boolean,
      default: false,
    },
    isClosable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.tag {
  background: $heritage-blue;
  border-radius: $radius-small;
  color: white;
  display: inline-block;
  padding: 5px 5px;
  flex: 1 1 auto;
  margin: 4px;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  div {
    display: inline-block;
  }
  .close-icon {
    svg {
      vertical-align: middle;
      margin-left: 20px;
    }
  }
}

.tag.team {
  background-color: $team;
}
.tag.person {
  background-color: $person;
}
.tag.product {
  background-color: $product;
}
.tag.skill {
  background-color: $skill;
}
.tag.service {
  background-color: $service;
}
.tag.room {
  background-color: $room;
}
.tag.business-unit {
  background-color: $business-unit;
}
.tag.active {
  background-color: $heritage-blue;
}
</style>
