import gql from 'graphql-tag';
import { v4 as uuidv4 } from 'uuid';
export default {
  methods: {
    async createOrUpdateLinks(links) {
      const createdOrUpdatedLinks = [];
      for (const link of links) {
        if (link.id && link.displayname && link.url) {
          await this.$apollo.mutate({
            mutation: gql`
              mutation(
                $id: ID!
                $displayname: String!
                $type: String!
                $url: String!
              ) {
                updateLinks(
                  where: { id: $id }
                  update: { displayname: $displayname, type: $type, url: $url }
                ) {
                  links {
                    id
                  }
                }
              }
            `,
            variables: {
              id: link.id,
              type: link.type,
              displayname: link.displayname,
              url: link.url,
            },
          });
          createdOrUpdatedLinks.push(link.id);
        } else if (link.displayname && link.url) {
          const id = uuidv4();
          link.id = id;
          await this.$apollo.mutate({
            mutation: gql`
              mutation(
                $id: ID!
                $displayname: String!
                $type: String!
                $url: String!
              ) {
                createLinks(
                  input: {
                    id: $id
                    displayname: $displayname
                    type: $type
                    url: $url
                  }
                ) {
                  links {
                    id
                  }
                }
              }
            `,
            variables: {
              id: id,
              type: link.type,
              displayname: link.displayname,
              url: link.url,
            },
          });
          createdOrUpdatedLinks.push(id);
        }
      }
      return createdOrUpdatedLinks;
    },
    removeLinkFromList(links, toRemove) {
      const filtered = links.filter((link) => link !== toRemove);
      this.$emit(`update:links`, filtered);
    },
    addEmptyLinkToList(links) {
      this.$emit(`update:links`, [
        ...links,
        {
          type: '',
          id: null,
          displayname: '',
          url: '',
        },
      ]);
    },
  },
};
