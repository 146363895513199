<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <TextInputWithLabel
        rules="required"
        name="name"
        label="Name"
        :value="name"
        @input="$emit('update:name', $event)"
      />
      <EmployeeSelector
        :value="employees"
        @input="$emit('update:employees', $event)"
        placeholder="Select a member"
      >
        People
      </EmployeeSelector>
      <slot name="buttonGroup" />
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import EmployeeSelector from '../selectors/EmployeeSelector';
import TextInputWithLabel from '../../basic/formfields/TextInputWithLabel';

export default {
  components: {
    ValidationObserver,
    EmployeeSelector,
    TextInputWithLabel,
  },
  props: ['name', 'employees'],
  methods: {
    submit() {
      this.$emit('submitForm');
    },
  },
};
</script>
