<template>
  <div id="primary_nav_wrap">
    <div class="nav displayAtLarge">
      <router-link tag="div" to="/people" class="nav-elem">
        <a>People</a>
      </router-link>
      <router-link tag="div" to="/teams" class="nav-elem">
        <a>Teams</a>
      </router-link>
      <router-link tag="div" to="/products" class="nav-elem">
        <a>Products</a>
      </router-link>
      <router-link tag="div" to="/services" class="nav-elem">
        <a>Services</a>
      </router-link>
      <router-link tag="div" to="/rooms" class="nav-elem">
        <a>Rooms</a>
      </router-link>
      <router-link tag="a" class="nav-elem circle" :to="{ path: editRoute }">
        <Pencil />
      </router-link>
    </div>
    <div class="dropdown displayAtSmall">
      <div class="dropdown-trigger">
        <div
          class="hamburger"
          v-on:click="toggleMobileMenu"
          :class="{ open: menuOpen }"
        >
          <div class="burger-line first"></div>
          <div class="burger-line second"></div>
          <div class="burger-line third"></div>
        </div>
        <div :class="{ open: menuOpen }" class="dropdown-list">
          <div class="menu-item">
            <router-link
              v-on:click.native="toggleMobileMenu"
              tag="div"
              :to="{ name: 'People' }"
            >
              <a>People</a>
            </router-link>
          </div>
          <div class="menu-item">
            <router-link
              v-on:click.native="toggleMobileMenu"
              tag="div"
              :to="{ name: 'Products' }"
            >
              <a>Products</a>
            </router-link>
          </div>
          <div class="menu-item">
            <router-link
              v-on:click.native="toggleMobileMenu"
              tag="div"
              :to="{ name: 'Teams' }"
            >
              <a>Teams</a>
            </router-link>
          </div>
          <div class="menu-item">
            <router-link
              v-on:click.native="toggleMobileMenu"
              tag="div"
              to="/services"
            >
              <a>Services</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '../router/index';
import Pencil from './basic/icons/Pencil';
export default {
  name: 'Navigation',
  router,
  components: {
    Pencil,
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleMobileMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
  computed: {
    editRoute() {
      if (this.$route.path.startsWith('/edit')) {
        return '';
      } else {
        return `/edit${this.$route.path}`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.hamburger {
  height: 45px;
  width: 45px;
  cursor: pointer;
  img {
    float: right;
    padding-right: 8px;
    height: 24px;
  }
  .burger-line {
    border-radius: $radius-small;
    height: 5px;
    background: white;
    margin-bottom: 8px;
    transition: transform 0.3s, opacity 0.3s;
    transform-origin: left;
  }
  &.open {
    .first {
      transform: rotate(45deg) translate(4px, -4px);
    }
    .second {
      opacity: 0;
      transform: translate(45px, 0);
    }
    .third {
      transform: rotate(-45deg) translate(0, 8px);
    }
  }
}
.dropdown,
.dropdown-list {
  list-style: none;
  color: white;
}
.dropdown a {
  text-decoration: none;
  color: white;
}
.dropdown-trigger {
  .dropdown-list {
    top: -160px;
    width: 100px;
    position: absolute;
    right: 20px;
    background-color: white;
    box-shadow: $box-shadow-emp;
    transition: top 0.5s;
    z-index: -1;
    &.open {
      top: $menu-height;
    }
    .menu-item {
      height: 40px;
      padding: 8px;
      a {
        color: $heritage-blue;
      }
    }
  }
}

.displayAtLarge {
  display: inline-flex;
  height: 100%;
}
.displayAtSmall {
  display: none;
}
@media (max-width: $mobile) {
  .displayAtLarge {
    display: none;
  }
  .displayAtSmall {
    display: inline-block;
  }
}
</style>
