<template>
  <Card>
    <section>
      <h1>About Nedap Family</h1>
      <Bar />
      <article>
        <section>
          <h2>Backstory</h2>
          <p>
            The Nedap Family project started in September 2018 at Healthcare. As
            Healthcare was growing there came a need to find people and the
            projects they worked on. Since Facebook@Work did not facilitate the
            non-hierarchical structure that is present at Nedap well, a group of
            people joined together to build a solution in-house. The goal was to
            build a product that fits the needs we have with new and interesting
            technology.
          </p>
        </section>
        <section>
          <h2>Manifest</h2>
          <p>
            The goal of Nedap Family is to facilitate Nedap people to get closer
            together. Nedap Family will help you find the person who can answer
            your question. Whether that question is: “Can I get access to X?” or
            “Would you recommend this technology and why and how would you
            implement it?”. This could be a person within your business unit,
            but could very well be a person from another business unit. As all
            the departments work on new solutions, Nedap Family helps you find
            out if somebody else already invented the weel and could help you
            get rolling faster. With so many awesome people we need a tool to
            find each other, that is what Nedap Family does.
          </p>
          <p>
            Nedap Family is a collaborative effort of us all. The Nedap Family
            core team will try to import as much data as possible to keep a lot
            of the information automatically up to date. However, not everything
            is importable, so we ask you users to update the information in
            Nedap Family too. This concerns of course your own information, but
            don't be shy and also update your colleague's information if you see
            that it is outdated.
          </p>
        </section>
        <section>
          <h2>Team</h2>
          <p>
            The Nedap Family core team consist of people from various business
            units. They all have their own expertise they bring to the team. The
            core team are:
          </p>
          <div class="nf-team">
            <Person
              v-for="employee in familyTeam"
              :key="employee.id"
              :employee="employee"
            ></Person>
          </div>
          <p>
            If you want to contact us you can join the
            <a href="https://nedap-healthcare.slack.com/archives/CD1FNBAVB"
              >Slack channel</a> or the <a href="https://teams.microsoft.com/l/channel/19%3a04138d694b6241f8b5178d73640acc90%40thread.tacv2/Nedap%2520Family?groupId=a11b6764-c088-40f1-8818-ca8367e4f4dd&tenantId=6d21d161-0ae7-4a24-94cf-df0881e2fa96">Teams channel</a>.
              If you come across bugs you can always mentions them on Slack, Teams or
            create an issue on
            <a href="github.com/nedap/nedap-family">GitHub</a>. If you want to
            join us to build on Nedap Family, feel free to reach out to one or
            all of us.
          </p>
        </section>
        <section>
          <h2>Way of working</h2>
          <p>
            The Nedap Family core team is a team of people with different
            strengths. Everyone who feels like they have something to contribute is welcome
            to do so. On GitHub, on Teams and in Slack everybody can
            mention bugs, raise awareness for improvements or just read along
            with the developments.
          </p>
        </section>
        <section>
          <h2>Tech & tools we use</h2>
          <p>
            As mentioned in the backstory Nedap Family is a project that uses
            technology that might not be as familiar within our customer facing
            products. In this case that is mostly our Neo4j database and our
            GraphQL API. We use VueJS as a front-end framework, which is now
            wider used. The Neo4j database is a graph database which allows us
            to describe the organigram really close to what it looks in the real
            world. Since there was a nice integration between GraphQL and Neo4j
            we decided to test this. The advantage is that you can specify in
            the front-end what data you need, this way you can fetch only that
            part of the object you need.
          </p>
          <section>
            <h3>Synchronising</h3>
            <p>
              We synchronise employees from Facebook@Work, this way we know in
              which department they are and we get their photo for free. When a
              employee is disabled in Workplace because they don't work at Nedap
              they are deleted from Nedap Family, however it could be that
              people's Workplace profile is deleted before being disabled, in
              that case you can remove that person manually and they will no
              longer turn up in Nedap Family.
            </p>
          </section>
        </section>
      </article>
    </section>
  </Card>
</template>

<script>
import Card from './basic/Card';
import Bar from './basic/Bar';
import Person from './employees/Person';
import gql from 'graphql-tag';

export default {
  name: 'AboutPage',
  components: {
    Card,
    Bar,
    Person,
  },
  data() {
    return {
      familyTeam: [],
    };
  },
  apollo: {
    familyTeam: {
      query() {
        return gql`
          query SearchQuery($taglist: [ID!]!, $substring: String!) {
            FilterEmployees(taglist: $taglist, substring: $substring) {
              id
              name
              mail
              workplaceProfile {
                picture
                is_silhouette
              }
              teams {
                id
                name
              }
              skills {
                id
                name
              }
              businessUnits {
                id
                name
              }
            }
          }
        `;
      },
      variables() {
        return {
          substring: '',
          taglist: ['193f56b8-31a3-49f7-b889-30bb3dd5fec1'], // Hardcode Nedap family id
        };
      },
      update(data) {
        return this.sortArrayCaseInsensitive(data.FilterEmployees);
      },
      // Additional options here
      fetchPolicy: 'no-cache',
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 80%;
  margin: 20px auto;
}
.nf-team {
  display: flex;
  flex-wrap: wrap;
  .person {
    margin-right: 20px;
  }
}
a {
  color: $spark-orange;
}
h2 {
  border-bottom: 2px solid $spark-orange;
}
</style>
