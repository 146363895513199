<template>
  <Card classes="full-width glance">
    <div class="name">
      <h1>{{ service.name }}</h1>
      <Bar />
    </div>
    <MarkDown :source="service.description" />
    <div class="superusers category" v-if="service.superusers.length > 0">
      <TagsContainerWithLabel
        label="Super user / Admin"
        pluralLabel="Super users / Admins"
        :tags="service.superusers"
        type="person"
        route="Employee"
      />
    </div>
    <Links :links="service.links" />
    <div class="button-group">
      <button class="default button" v-on:click="edit(service.id)">Edit</button>
      <BackButton title="OK" classes="submit" />
    </div>
  </Card>
</template>

<script>
import MarkDown from '../basic/MarkDown';
import gql from 'graphql-tag';
import TagsContainerWithLabel from '../basic/tags/TagsContainerWithLabel';
import BackButton from '../basic/BackButton';
import Bar from '../basic/Bar';
import Card from '../basic/Card';
import Links from '../basic/Links';

export default {
  name: 'ServicePage',
  components: {
    TagsContainerWithLabel,
    BackButton,
    Card,
    Bar,
    Links,
    MarkDown,
  },
  data() {
    return {
      service: {
        id: '',
        name: '',
        description: '',
        superusers: [],
        links: [],
      },
    };
  },
  methods: {
    edit(id) {
      this.$router.push({ name: 'Service Edit', params: { id } });
    },
  },
  apollo: {
    service: {
      query: gql`
        query($serviceId: ID!) {
          services(where: { id: $serviceId }) {
            id
            name
            description
            superusers {
              id
              name
            }
            links {
              id
              displayname
              url
              type
            }
          }
        }
      `,
      variables() {
        return {
          serviceId: this.$route.params.id,
        };
      },
      update(data) {
        return data.services[0];
      },
    },
  },
};
</script>
