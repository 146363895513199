<template>
  <Card classes="full-width glance">
    <div class="name">
      <h1>{{ room.code }} {{ room.name }}</h1>
      <Bar />
    </div>
    <div v-if="room.outlookname" class="category">
      <div class="label">Outlook name</div>
      <div class="value">
        <img src="@/assets/LogoOutlook.svg" alt="Outlook logo" />
        {{ room.outlookname }}
      </div>
    </div>
    <div class="alias category" v-if="room.aliases">
      <div class="label">Aliases</div>
      {{ aliasesList(room.aliases) }}
    </div>
    <div v-if="room.capacity >= 0" class="category">
      <div class="label">Capacity</div>
      {{ room.capacity }} <span v-if="room.workplace">workplaces</span>
    </div>
    <div class="category">
      <div class="label">Zoom</div>
      <div class="value">
        <CheckMark v-if="room.zoom" color="green" />
        <Close v-else color="red" />
        {{ zoomInfo }}
      </div>
    </div>
    <div class="category">
      <div class="label">Screen + HDMI</div>
      <div class="value">
        <CheckMark v-if="room.byod" color="green" />
        <Close v-else color="red" />
        {{ byodInfo }}
      </div>
    </div>
    <div class="category">
      <div class="label">Wheelchair accessible</div>
      <div class="value">
        <CheckMark v-if="room.wheelchair" color="green" />
        <Close v-else color="red" />
        {{ wheelchairInfo }}
      </div>
    </div>
    <div class="category">
      <div class="label">Whiteboard available</div>
      <div class="value">
        <CheckMark v-if="room.whiteboard" color="green" />
        <Close v-else color="red" />
        {{ whiteboardInfo }}
      </div>
    </div>
    <TagsContainerWithLabel
      v-if="room.businessUnits.length > 0"
      label="Business unit"
      pluralLabel="Business units"
      :tags="room.businessUnits"
      type="business-unit"
    />
    <div class="button-group">
      <button class="default button" @click="edit(room.id)">Edit</button>
      <BackButton title="OK" classes="submit" />
    </div>
  </Card>
</template>

<script>
import gql from 'graphql-tag';
import Card from '../basic/Card';
import BackButton from '../basic/BackButton';
import Bar from '../basic/Bar';
import Close from '../basic/icons/Close';
import CheckMark from '../basic/icons/CheckMark';
import TagsContainerWithLabel from '../basic/tags/TagsContainerWithLabel';

export default {
  name: 'RoomPage',
  components: {
    Card,
    BackButton,
    Bar,
    Close,
    CheckMark,
    TagsContainerWithLabel,
  },
  data() {
    return {
      room: {
        name: '',
        code: '',
        outlookname: '',
        aliases: [],
        zoom: false,
        byod: false,
        wheelchair: false,
        whiteboard: false,
        workplace: false,
        capaicty: 0,
        businessUnits: [],
      },
    };
  },
  computed: {
    zoomInfo() {
      return this.room.zoom
        ? 'This room has a zoom setup, no need to bring your laptop.'
        : 'This room has no zoom setup.';
    },
    byodInfo() {
      let message = '';
      if (this.room.byod) {
        message =
          'There is a screen present. You can also plugin your laptop via HDMI.';
      } else {
        message = 'There is no screen you can use in this room.';
      }
      return message;
    },
    wheelchairInfo() {
      return this.room.wheelchair
        ? 'This room is accessible by wheelchair'
        : 'This room is not accessible by wheelchair';
    },
    whiteboardInfo() {
      return this.room.whiteboard
        ? 'There is a whiteboard present.'
        : 'There is no whiteboard present.';
    },
  },
  methods: {
    aliasesList(aliases) {
      return aliases.join(', ');
    },
    edit(id) {
      this.$router.push({ name: 'Room Edit', params: { id } });
    },
  },
  apollo: {
    room: {
      query: gql`
        query SearchRoom($roomId: ID!) {
          rooms(where: { id: $roomId }) {
            id
            name
            code
            outlookname
            aliases
            zoom
            byod
            wheelchair
            capacity
            whiteboard
            workplace
            businessUnits {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          roomId: this.$route.params.id,
        };
      },
      update(data) {
        return data.rooms[0];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  height: 1em;
  width: 1em;
  margin-right: 10px;
}
.card .category .value {
  display: flex;
  align-items: center;
}
</style>
