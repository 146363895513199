<template>
  <div class="container">
    <Modal
      v-if="confirmModal"
      :name="skill.name"
      @confirm="deleteSkill"
      @cancel="cancelDelete"
    />
    <Card>
      <h1>Edit {{ skill.name }}</h1>
      <SkillForm v-bind.sync="skill" @submitForm="submit">
        <div slot="buttonGroup" class="button-group">
          <button class="button default" type="button" v-on:click="cancel()">
            Cancel
          </button>
          <button
            class="delete button"
            type="button"
            v-on:click="confirmDelete()"
          >
            Delete
          </button>
          <button class="submit button" type="submit">Save</button>
        </div>
      </SkillForm>
    </Card>
  </div>
</template>

<script>
import Modal from '../../basic/Modal';
import Card from '../../basic/Card';
import gql from 'graphql-tag';
import SkillForm from './SkillForm';

export default {
  components: {
    Modal,
    Card,
    SkillForm,
  },
  data() {
    return {
      skill: {
        name: '',
        employees: [],
      },
      confirmModal: false,
    };
  },
  apollo: {
    skill: {
      query: gql`
        query SearchSkill($skillId: ID!) {
          skills(where: { id: $skillId }) {
            id
            name
            employees {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          skillId: this.$route.params.id,
        };
      },
      update(data) {
        return data.skills[0];
      },
    },
  },
  methods: {
    confirmDelete() {
      this.confirmModal = true;
    },
    cancelDelete() {
      this.confirmModal = false;
    },
    async deleteSkill() {
      const name = this.skill.name;
      await this.$apollo.mutate({
        mutation: gql`
          mutation($id: ID!) {
            deleteSkills(where: { id: $id }) {
              nodesDeleted
            }
          }
        `,
        variables: {
          id: this.skill.id,
        },
      });
      await this.$apollo.getClient().clearStore();
      this.$toasted.global.update_toaster({
        message: `You have successfully removed ${name}`,
      });
      this.$router.replace({ name: 'Skill Create' });
    },
    async updateSkill() {
      const data = {
        skill: {
          id: this.skill.id,
          name: this.skill.name,
        },
        relations: {
          employeeIds: this.skill.employees.map((entry) => entry.id),
        },
      };
      await this.$apollo.mutate({
        mutation: gql`
          mutation($data: CreateAndUpdateSkillInput!) {
            CreateAndUpdateSkill(data: $data) {
              id
            }
          }
        `,
        variables: {
          data,
        },
      });
      await this.$apollo.getClient().clearStore();
      this.$toasted.global.update_toaster({
        message: `You have successfully updated ${this.skill.name}`,
      });
    },
    async submit() {
      await this.updateSkill();
      this.$router.back();
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>
