<template>
  <div class="container">
    <Card classes="container left">
      <h1>Create a service</h1>
      <ServiceForm v-bind.sync="service" @submitForm="submit" ref="serviceForm">
        <div slot="buttonGroup" class="button-group">
          <button
            class="button default"
            type="button"
            id="cancelButton"
            @click="resetInterface()"
          >
            Cancel
          </button>
          <button type="submit" class="submit button" :disabled="submitting">
            Create
          </button>
        </div>
      </ServiceForm>
    </Card>
    <Card classes="container right">
      <h1>Edit a service</h1>
      <ServiceList />
    </Card>
  </div>
</template>

<script>
import ServiceList from './ServiceList';
import { v4 as uuidv4 } from 'uuid';
import Card from '../../basic/Card';
import gql from 'graphql-tag';
import ServiceForm from './ServiceForm';
import linksCrudMixin from '@/utils/linksCrudMixin';

const emptyService = {
  name: '',
  description: '',
  superusers: [],
  businessUnits: [],
  links: [],
};

export default {
  components: {
    ServiceList,
    ServiceForm,
    Card,
  },
  mixins: [linksCrudMixin],
  data() {
    return {
      service: { ...emptyService },
      submitting: false,
    };
  },
  methods: {
    resetInterface() {
      Object.assign(this.service, emptyService);
      this.$nextTick(() => {
        this.$refs.serviceForm.$refs.form?.reset();
      });
    },
    async createService() {
      const createdLinks = await this.createOrUpdateLinks(this.service.links);
      const data = {
        service: {
          id: uuidv4(),
          name: this.service.name,
          description: this.service.description,
        },
        relations: {
          superUserIds: this.service.superusers.map((entry) => entry.id),
          linkIds: createdLinks,
          businessUnitIds: this.service.businessUnits.map((entry) => entry.id),
        },
      };
      await this.$apollo.mutate({
        mutation: gql`
          mutation($data: CreateAndUpdateServiceInput!) {
            CreateAndUpdateService(data: $data) {
              id
            }
          }
        `,
        variables: {
          data,
        },
      });
      this.$toasted.global.update_toaster({
        message: `You have successfully created ${this.service.name}`,
      });
      await this.$apollo.getClient().resetStore();
    },
    async submit() {
      this.submitting = true;
      try {
        await this.createService();
        this.resetInterface();
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
