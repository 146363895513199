<template>
  <div class="employees">
    <div class="employee-container">
      <div class="search-bar-header">
        <SearchBar v-model="query" />
      </div>
      <EmployeeGridMobile :employees="currentEmployees" />
      <EmployeeGrid :employees="currentEmployees" />
    </div>
    <SideBar />
  </div>
</template>

<script>
import EmployeeGrid from './EmployeeGrid';
import SideBar from './sidebar/SideBar';
import SearchBar from '../basic/searchbar/SearchBar';
import EmployeeGridMobile from '../mobile/EmployeeGridMobile';
import { mapState } from 'vuex';
import gql from 'graphql-tag';

export default {
  name: 'Employees',
  components: {
    EmployeeGridMobile,
    EmployeeGrid,
    SearchBar,
    SideBar,
  },
  data() {
    return {
      employees: [],
      query: '',
    };
  },
  computed: {
    ...mapState(['employee_filter']),
    currentEmployees() {
      return this.employees.filter((employee) => {
        return employee.name.toLowerCase().includes(this.query.toLowerCase());
      });
    },
  },
  apollo: {
    employees: {
      query() {
        return gql`
          query SearchQuery($taglist: [ID!]!, $substring: String!) {
            searchEmployees(taglist: $taglist, substring: $substring) {
              id
              name
              mail
              workplaceProfile {
                picture
                is_silhouette
              }
              teams {
                id
                name
              }
              skills {
                id
                name
              }
              businessUnits {
                id
                name
              }
              productowner {
                id
                name
              }
            }
          }
        `;
      },
      variables() {
        return {
          substring: this.query,
          taglist: this.employee_filter,
        };
      },
      update(data) {
        return data.searchEmployees;
      },
      // Additional options here
      fetchPolicy: 'no-cache',
    },
  },
};
</script>

<style lang="scss" scoped>
.employees {
  display: flex;
  .employee-container {
    flex-grow: 1; /* this makes sure the container grows after collapsing the sidebar */
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }
}
</style>
