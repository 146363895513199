<template>
  <div class="search-bar">
    <SearchIcon />
    <input
      :value="value"
      @input="$emit('input', $event.target.value)"
      placeholder="Search"
    />
    <SearchClear @clearsearch="$emit('input', '')" />
  </div>
</template>

<script>
import SearchIcon from './SearchIcon';
import SearchClear from './SearchClear';
export default {
  name: 'SearchBar',
  components: {
    SearchIcon,
    SearchClear,
  },
  props: {
    value: String,
  },
};
</script>

<style scoped lang="scss">
.search-bar {
  display: inline-flex;
  align-items: center;
  height: 30px;
  width: 100%;
  background: white;
  border: 1px solid $light-grey;
  border-radius: $radius-xlarge;
  text-align: center;
  transition: border 0.2s;
  &:focus-within {
    border: 2px solid $spark-orange;
  }
  input {
    flex-grow: 1;
    outline: none;
    border: none;
    text-align: center;
  }
  .icon {
    padding: 0 10px;
  }
  .search-clear {
    cursor: pointer;
  }
}
</style>
