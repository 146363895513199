<template>
  <aside class="side-bar" :class="{ active: isActive }">
    <button class="toggle-button" @click="toggleNav()">
      Filters ({{ amountSelectedFilters }})
    </button>
    <div class="content">
      <div class="sticky-header">
        <div class="search-bar">
          <SearchBar v-model="query" />
        </div>
        <SelectedTagList />
      </div>
      <div class="accordion">
        <TeamList :query="query" />
        <ProductList :query="query" />
        <ServiceList :query="query" />
        <SkillList :query="query" />
      </div>
    </div>
  </aside>
</template>

<script>
import TeamList from './TeamList';
import ProductList from './ProductList';
import ServiceList from './ServiceList';
import SkillList from './SkillList';
import SearchBar from '../../basic/searchbar/SearchBar';
import SelectedTagList from './SelectedTagList';

export default {
  components: {
    TeamList,
    ProductList,
    ServiceList,
    SkillList,
    SearchBar,
    SelectedTagList,
  },
  data() {
    return {
      query: '',
      isActive: true,
    };
  },
  computed: {
    amountSelectedFilters() {
      return this.$store.state.employee_filter.length;
    },
  },
  methods: {
    toggleNav() {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style scoped lang="scss">
.side-bar {
  height: calc(100vh - #{$menu-height});
  background: #fff;
  z-index: $sidebar-index;
  position: sticky;
  top: $menu-height;
  .content {
    display: none;
    overflow-y: auto;
    max-height: 100%;
    .accordion {
      height: 100%;
    }
    .search-bar {
      width: 100%;
      display: inline-flex;
      position: relative;
    }
    .sticky-header {
      position: sticky;
      top: 0;
      z-index: $sidebar-index;
      background-color: white;
      padding: 10px 0;
      margin-top: 10px;
      box-shadow: $box-shadow-sticky-header;
    }
  }
  .toggle-button {
    cursor: pointer;
    position: absolute;
    right: 0;
    transform: rotate(-90deg) translateX(20px);
    transform-origin: bottom right;
    padding: 8px;
    border-radius: $radius-small $radius-small 0 0;
    box-shadow: $box-shadow-card;
    color: white;
    background-color: $spark-orange;
    border: none;
    white-space: nowrap;
    margin: 0;
    font-size: $font-size-small;
  }
}
.side-bar.active {
  display: block;
  width: 20%;
  min-width: 275px;
  flex-grow: 1;
  .toggle-button {
    right: 100%;
  }
  .content {
    display: block;
    padding: 10px;
  }
}
@media (max-width: $extra-small) {
  .side-bar {
    right: 0;
  }
}
</style>
