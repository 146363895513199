<template>
  <div class="flex-form">
    <label><slot></slot></label>
    <multiselect
      class="multi-select skill"
      ref="skillselector"
      :loading="loading()"
      :multiple="true"
      :taggable="true"
      :hide-selected="true"
      :value="sortedValue"
      label="name"
      track-by="id"
      :options="options"
      placeholder="Select a skill"
      tag-placeholder="Prese enter to create a new skill"
      @tag="addTag"
      @input="handleInput"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'SkillSelector',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    sortedValue() {
      return this.sortArrayCaseInsensitive(this.value);
    },
  },
  data() {
    return {
      loadingTasks: 0,
      options: [],
    };
  },
  methods: {
    handleInput(input) {
      this.$emit('input', input);
      this.$refs.skillselector.$el.focus();
    },
    loading() {
      return this.loadingTasks !== 0;
    },
    addTag(name) {
      const id = uuidv4();
      const tag = {
        __typename: 'Skill',
        name,
        id,
      };
      this.loadTask(async () => {
        await this.$apollo.mutate({
          mutation: gql`
            mutation($id: ID!, $name: String!) {
              createSkills(input: { id: $id, name: $name }) {
                skills {
                  id
                }
              }
            }
          `,
          variables: {
            id,
            name,
          },
        });
        this.options.push(tag);
        this.$emit('input', this.value.concat(tag));
        this.$refs.skillselector.$el.focus();
      })
        .then()
        .catch((err) => {
          this.$toasted.global.update_toaster({
            message: `The skill ${name} could not be created. Please try again`,
            type: 'error',
          });
          throw err;
        });
    },
    async loadTask(task) {
      this.loadingTasks++;
      try {
        const result = await task();
        return result;
      } finally {
        this.loadingTasks--;
      }
    },
  },
  apollo: {
    options: {
      query: gql`
        query {
          skills {
            id
            name
          }
        }
      `,
      update({ skills }) {
        return this.sortArrayCaseInsensitive(skills);
      },
    },
  },
};
</script>
