<template>
  <div class="employee--grid">
    <div class="person" v-for="employee in employees" :key="employee.id">
      <div class="photo">
        <ProfilePicture isMobile :employee="employee" />
      </div>
      <div class="info">
        <h1 class="name">{{ employee.name }}</h1>
        <Bar />
        <TagsContainer :tags="employee.teams" type="team" />
      </div>
      <router-link :to="{ name: 'Employee', params: { id: employee.id } }">
        <More color="#b4c4cb" />
      </router-link>
    </div>
  </div>
</template>

<script>
import TagsContainer from '../basic/tags/TagsContainer';
import Bar from '../basic/Bar';
import ProfilePicture from '../employees/ProfilePicture';
import More from '../basic/icons/More';

export default {
  name: 'EmployeeGridMobile',
  components: { TagsContainer, ProfilePicture, Bar, More },
  props: {
    employees: Array,
  },
};
</script>

<style scoped lang="scss">
.employee--grid {
  position: relative;
  overflow-y: scroll;
  padding: 0px 20px 30px 20px;
  display: none;
  .person {
    display: flex;
    justify-content: space-around;
    background-color: white;
    border-radius: $radius-medium;
    box-shadow: $box-shadow-card;
    margin-bottom: 10px;
    padding: 8px;
    .photo {
      width: 20%;
      display: flex;
    }
    .info {
      display: inline-block;
      padding-left: 20px;
      width: 80%;
      .name {
        font-size: $font-size-large;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .content {
        margin-top: 8px;
      }
    }
  }
}
@media (max-width: $mobile) {
  .employee--grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
}
</style>
