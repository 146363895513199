<template>
  <div>
    <div class="links" v-for="(link, index) in links" :key="index">
      <LinkIconDropdown v-model="link.type" />
      <TextInput
        classes="full-height"
        class="display-name"
        rules="max:30"
        v-model="link.displayname"
        placeholder="display name..."
        :name="`Display-name ${link.type}`"
      />
      <TextInput
        classes="full-height"
        class="url"
        rules="url"
        v-model="link.url"
        placeholder="URL..."
        :name="`URL ${link.type}`"
      />
      <button class="remove" @click="$emit('deleteLink', link)">
        <Close color="#023A4F" />
      </button>
    </div>
  </div>
</template>

<script>
import Close from '../../basic/icons/Close';
import LinkIconDropdown from './LinkIconDropdown';
import TextInput from '../../basic/formfields/TextInput';

export default {
  components: {
    LinkIconDropdown,
    TextInput,
    Close,
  },
  props: {
    links: {
      type: Array,
    },
  },
};
</script>

<style scoped lang="scss">
.links {
  display: grid;
  grid-template-columns: 85px 1fr 1fr 12px;
  column-gap: 16px;
  margin-bottom: 10px;

  .remove {
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
</style>
