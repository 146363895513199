<template>
  <div>
    <SearchableTagsContainer
      :tags="filteredProducts"
      type="product"
      :route="'Product Edit'"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';
import SearchableTagsContainer from '../../basic/tags/SearchableTagsContainer';

export default {
  name: 'ProductTagList',
  components: {
    SearchableTagsContainer,
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapState(['selected_business_unit']),
    filteredProducts() {
      return this.products.filter((product) => {
        return (
          this.selected_business_unit === '' ||
          product.businessUnits.some(
            (bu) => this.selected_business_unit === bu.id
          )
        );
      });
    },
  },
  apollo: {
    products: {
      query: gql`
        query {
          products {
            id
            name
            businessUnits {
              id
            }
          }
        }
      `,
      update({ products }) {
        return this.sortArrayCaseInsensitive(products);
      },
    },
  },
};
</script>
