<template>
  <Card classes="full-width glance">
    <div class="name">
      <h1>{{ team.name }}</h1>
      <Bar />
    </div>
    <MarkDown :source="team.description" />
    <TagsContainerWithLabel
      v-if="team.members.length > 0"
      label="Member"
      pluralLabel="Members"
      :tags="team.members"
      type="person"
      route="Employee"
    />
    <TagsContainerWithLabel
      v-if="team.products.length > 0"
      label="Product"
      pluralLabel="Products"
      :tags="team.products"
      type="product"
      route="Product"
    />
    <TagsContainerWithLabel
      label="Business unit"
      pluralLabel="Business units"
      :tags="team.businessUnits"
      type="business-unit"
    />
    <Links :links="team.links" />
    <div class="button-group">
      <button class="default button" @click="edit(team.id)">Edit</button>
      <BackButton title="OK" classes="submit" />
    </div>
  </Card>
</template>

<script>
import gql from 'graphql-tag';
import TagsContainerWithLabel from '../basic/tags/TagsContainerWithLabel';
import Card from '../basic/Card';
import BackButton from '../basic/BackButton';
import Bar from '../basic/Bar';
import Links from '../basic/Links';
import MarkDown from '../basic/MarkDown';

export default {
  name: 'TeamPage',
  components: {
    TagsContainerWithLabel,
    Card,
    BackButton,
    Bar,
    Links,
    MarkDown,
  },
  data() {
    return {
      team: {
        name: '',
        description: '',
        members: [],
        products: [],
        links: [],
        businessUnits: [],
      },
    };
  },
  methods: {
    edit(id) {
      this.$router.push({ name: 'Team Edit', params: { id } });
    },
  },
  apollo: {
    team: {
      query: gql`
        query SearchTeam($teamId: ID!) {
          teams(where: { id: $teamId }) {
            id
            name
            description
            members {
              id
              name
            }
            products {
              id
              name
            }
            links {
              id
              displayname
              url
              type
            }
            businessUnits {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          teamId: this.$route.params.id,
        };
      },
      update(data) {
        return data.teams[0];
      },
    },
  },
};
</script>
