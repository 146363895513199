<template>
  <div class="list">
    <FilterTag
      v-for="tag in tags"
      :key="tag.id"
      :type="type"
      :id="tag.id"
      :title="tag.name"
    ></FilterTag>
  </div>
</template>

<script>
import FilterTag from '../../basic/tags/FilterTag';

export default {
  components: {
    FilterTag,
  },
  props: {
    tags: Array,
    type: String,
  },
};
</script>

<style scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;
}
</style>
