<template>
  <img class="logo" :src="source()" :alt="type" />
</template>

<script>
export default {
  name: 'LinkIconGenerator',
  model: {
    prop: 'type',
  },
  props: {
    type: String,
  },
  methods: {
    source() {
      return require(`@/assets/Logo${this.type}.svg`);
    },
  },
};
</script>
<style scoped>
.logo {
  width: 32px;
  height: 32px;
}
</style>
