<template>
  <div v-if="tags.length > 0" class="selected-tag-list">
    <ClosableTag
      v-for="tag in tags"
      :key="tag.id"
      :title="tag.name"
      :id="tag.id"
      isActive
    />
  </div>
</template>

<script>
import ClosableTag from '../../basic/tags/ClosableTag';
import { mapState } from 'vuex';
import gql from 'graphql-tag';

export default {
  name: 'SelectedTagList',
  components: {
    ClosableTag,
  },
  data() {
    return {
      tags: [],
    };
  },
  computed: {
    ...mapState(['employee_filter', 'selected_business_unit']),
  },
  apollo: {
    tags: {
      query() {
        return gql`
          query TagQuery($taglist: [ID!]!) {
            GetTags(taglist: $taglist) {
              id
              name
            }
          }
        `;
      },
      variables() {
        return {
          taglist: this.employee_filter.filter(
            (tag) => tag !== this.selected_business_unit
          ),
        };
      },
      update(data) {
        return data.GetTags;
      },
      // Additional options here
      fetchPolicy: 'no-cache',
    },
  },
};
</script>
<style>
.selected-tag-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
</style>
