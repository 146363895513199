<template>
  <div class="logo">
    <router-link :to="{ name: 'About' }" active-class class="displayAtSmall">
      <img alt src="@/assets/nedap-spark.svg" />
    </router-link>
    <router-link :to="{ name: 'About' }" active-class class="displayAtLarge">
      <img alt src="@/assets/LOGO_NedapFamily.svg" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'AboutNavigator',
};
</script>

<style lang="scss" scoped>
.logo {
  margin-right: 20px;
  & img {
    height: 30px;
  }
}
.displayAtLarge {
  display: inline-block;
}
.displayAtSmall {
  display: none;
}
@media (max-width: $mobile) {
  .displayAtLarge {
    display: none;
  }
  .displayAtSmall {
    display: inline-block;
  }
}
</style>
