<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <TextInputWithLabel
        rules="required"
        name="name"
        label="Name"
        :value="name"
        @input="$emit('update:name', $event)"
      />
      <TextAreaWithLabel
        rules="required"
        label="Description"
        name="product-description"
        :value="description"
        @input="$emit('update:description', $event)"
      />
      <div class="flex-form">
        <label for="aliases">Aliases</label>
        <multiselect
          class="multi-select product"
          :multiple="true"
          :taggable="true"
          :value="aliases"
          @input="$emit('update:aliases', $event)"
          :options="[]"
          @tag="addTag"
          placeholder="Add aliases"
          tag-placeholder="Press enter to create a new alias"
        />
      </div>
      <EmployeeSelector
        :value="productowner"
        @input="$emit('update:productowner', $event)"
        placeholder="Select a product owner"
      >
        <Pluralize
          singular="Product owner"
          multiple="Product owners"
          :count="productowner.length"
        />
      </EmployeeSelector>
      <TeamSelector :value="teams" @input="$emit('update:teams', $event)">
        <Pluralize singular="Team" multiple="Teams" :count="teams.length" />
      </TeamSelector>
      <BusinessUnitSelector
        :value="businessUnits"
        @input="$emit('update:businessUnits', $event)"
      />
      <Links
        @deleteLink="removeLinkFromList(links, $event)"
        :links="links"
        :value="links"
        @input="$emit('update:links', $event)"
      />
      <button
        class="button default"
        @click="addEmptyLinkToList(links)"
        type="button"
      >
        Add Link
      </button>
      <slot name="buttonGroup" />
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import TeamSelector from '../selectors/TeamSelector';
import EmployeeSelector from '../selectors/EmployeeSelector';
import BusinessUnitSelector from '../selectors/BusinessUnitSelector';
import Pluralize from '../../basic/Pluralize';
import Links from '../../basic/formfields/Links';
import TextInputWithLabel from '../../basic/formfields/TextInputWithLabel';
import TextAreaWithLabel from '../../basic/formfields/TextAreaWithLabel';
import linksCrudMixin from '@/utils/linksCrudMixin';

export default {
  components: {
    ValidationObserver,
    EmployeeSelector,
    TeamSelector,
    BusinessUnitSelector,
    Links,
    TextInputWithLabel,
    TextAreaWithLabel,
    Pluralize,
  },
  props: [
    'name',
    'description',
    'teams',
    'aliases',
    'links',
    'businessUnits',
    'productowner',
  ],
  mixins: [linksCrudMixin],
  methods: {
    submit() {
      this.$emit('submitForm');
    },
    addTag(name) {
      this.$emit(`update:aliases`, [...this.aliases, name]);
    },
  },
};
</script>
