<template>
  <div>
    <SearchableTagsContainer :tags="rooms" type="room" :route="'Room Edit'" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import SearchableTagsContainer from '../../basic/tags/SearchableTagsContainer';

export default {
  name: 'RoomTagList',
  components: {
    SearchableTagsContainer,
  },
  data() {
    return {
      rooms: [],
    };
  },
  apollo: {
    rooms: {
      query: gql`
        query {
          rooms {
            id
            name
            code
          }
        }
      `,
      update({ rooms }) {
        let sortedRooms = this.sortArrayCaseInsensitive(rooms);
        return sortedRooms.map(({ ...room }) => {
          let newName = `${room.code} ${room.name}`;
          room.name = newName;
          return room;
        });
      },
    },
  },
};
</script>
