<template>
  <svg
    width="14"
    height="4"
    viewBox="0 0 14 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.74093 0C0.797928 0 0 0.797928 0 1.74093C0 2.68394 0.797928 3.48186 1.74093 3.48186C2.68394 3.48186 3.48187 2.68394 3.48187 1.74093C3.48187 0.797928 2.68394 0 1.74093 0Z"
      :fill="color"
    />
    <path
      d="M6.9637 0C6.02069 0 5.22276 0.797928 5.22276 1.74093C5.22276 2.68394 6.02069 3.48186 6.9637 3.48186C7.9067 3.48186 8.70463 2.68394 8.70463 1.74093C8.70463 0.797928 7.97924 0 6.9637 0Z"
      :fill="color"
    />
    <path
      d="M10.5181 1.74093C10.5181 0.797928 11.3161 0 12.2591 0C13.2021 0 14 0.797928 14 1.74093C14 2.68394 13.2021 3.48186 12.2591 3.48186C11.3161 3.48186 10.5181 2.68394 10.5181 1.74093Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'More',
  props: {
    color: {
      default: 'white',
      type: String,
    },
  },
};
</script>
<style scoped lang="scss">
svg {
  transform: rotate(90deg);
}
</style>
