<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <TextInputWithLabel
        rules="required"
        name="name"
        label="Name"
        :value="name"
        @input="$emit('update:name', $event)"
      />
      <TextInputWithLabel
        rules="required"
        name="code"
        label="Room code"
        :value="code"
        @input="$emit('update:code', $event)"
      />
      <TextInputWithLabel
        name="outlookname"
        label="Outlook name"
        :value="outlookname"
        @input="$emit('update:outlookname', $event)"
      />
      <TextInputWithLabel
        name="location"
        label="Location"
        :value="location"
        @input="$emit('update:location', $event)"
      />
      <div class="location-info">
        When in Groenlo <b>+Groenlo Building</b>, and for other locations the
        first part of the Outlook name
      </div>
      <NumberInputWithLabel
        rules="required"
        name="capacity"
        label="Capacity"
        :value="capacity"
        @input="$emit('update:capacity', $event)"
      />
      <CheckBoxWithLabel
        name="zoom"
        label="Zoom"
        :value="zoom"
        @input="$emit('update:zoom', $event)"
      />
      <CheckBoxWithLabel
        name="byod"
        label="Screen + HDMI"
        :value="byod"
        @input="$emit('update:byod', $event)"
      />
      <CheckBoxWithLabel
        name="wheelchair"
        label="Wheelchair accessible"
        :value="wheelchair"
        @input="$emit('update:wheelchair', $event)"
      />
      <CheckBoxWithLabel
        name="whiteboard"
        label="Whiteboard available"
        :value="whiteboard"
        @input="$emit('update:whiteboard', $event)"
      />
      <CheckBoxWithLabel
        name="workplace"
        label="Workplaces with monitors"
        :value="workplace"
        @input="$emit('update:workplace', $event)"
      />
      <div class="flex-form">
        <label for="aliases">Aliases</label>
        <multiselect
          class="multi-select room"
          :multiple="true"
          :taggable="true"
          :value="aliases"
          @input="$emit('update:aliases', $event)"
          :options="[]"
          @tag="addTag"
          placeholder="Add aliases"
          tag-placeholder="Press enter to create a new alias"
        />
      </div>
      <BusinessUnitSelector
        :value="businessUnits"
        required=""
        @input="$emit('update:businessUnits', $event)"
      />
      <slot name="buttonGroup" />
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import BusinessUnitSelector from '../selectors/BusinessUnitSelector';
import TextInputWithLabel from '../../basic/formfields/TextInputWithLabel';
import NumberInputWithLabel from '../../basic/formfields/NumberInputWithLabel.vue';
import CheckBoxWithLabel from '../../basic/formfields/CheckBoxWithLabel.vue';

export default {
  components: {
    ValidationObserver,
    BusinessUnitSelector,
    TextInputWithLabel,
    NumberInputWithLabel,
    CheckBoxWithLabel,
  },
  props: [
    'name',
    'code',
    'location',
    'outlookname',
    'aliases',
    'capacity',
    'zoom',
    'byod',
    'wheelchair',
    'whiteboard',
    'workplace',
    'businessUnits',
  ],
  methods: {
    submit() {
      this.$emit('submitForm');
    },
    addTag(name) {
      this.$emit(`update:aliases`, [...this.aliases, name]);
    },
  },
};
</script>

<style lang="scss" scoped>
.location-info {
  margin: -20px 0 20px 30%;
  font-style: italic;
}
</style>
