import Vue from 'vue';
import Router from 'vue-router';
import Products from '../components/products/Products';
import Teams from '../components/teams/Teams';
import Employees from '../components/employees/Employees';
import EditPage from '../components/edit/EditPage';
import EmployeeCreate from '../components/edit/employee/EmployeeCreate';
import TeamCreate from '../components/edit/team/TeamCreate';
import TeamEdit from '../components/edit/team/TeamEdit';
import ProductCreate from '../components/edit/product/ProductCreate';
import ProductEdit from '../components/edit/product/ProductEdit';
import EmployeeEdit from '../components/edit/employee/EmployeeEdit';
import SkillCreate from '../components/edit/skill/SkillCreate';
import SkillEdit from '../components/edit/skill/SkillEdit';
import ProfilePage from '../components/employees/ProfilePage';
import AuthenticationError from '../components/AuthenticationError';
import ProductPage from '../components/products/ProductPage';
import TeamPage from '../components/teams/TeamPage';
import ServiceList from '../components/services/Services';
import ServiceCreate from '../components/edit/service/ServiceCreate';
import ServiceEdit from '../components/edit/service/ServiceEdit';
import ServicePage from '../components/services/ServicePage';
import Rooms from '../components/rooms/Rooms';
import RoomPage from '../components/rooms/RoomPage';
import RoomEdit from '../components/edit/room/RoomEdit';
import RoomCreate from '../components/edit/room/RoomCreate';
import AboutPage from '../components/AboutPage';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      redirect: '/people',
      meta: { withBUDropdown: true },
    },
    {
      path: '/about',
      name: 'About',
      component: AboutPage,
      meta: { withBUDropdown: false },
    },
    {
      path: '/products',
      name: 'Products',
      component: Products,
      meta: { withBUDropdown: true },
    },
    {
      path: '/people',
      name: 'People',
      component: Employees,
      meta: { withBUDropdown: true },
    },
    {
      path: '/services',
      name: 'Services',
      component: ServiceList,
      meta: { withBUDropdown: true },
    },
    {
      path: '/teams',
      name: 'Teams',
      component: Teams,
      meta: { withBUDropdown: true },
    },
    {
      path: '/rooms',
      name: 'Rooms',
      component: Rooms,
      meta: { withBUDropdown: true },
    },
    {
      path: '/people/:id',
      name: 'Employee',
      component: ProfilePage,
      meta: { withBUDropdown: false },
    },
    {
      path: '/products/:id',
      name: 'Product',
      component: ProductPage,
      meta: { withBUDropdown: false },
    },
    {
      path: '/services/:id',
      name: 'Service',
      component: ServicePage,
      meta: { withBUDropdown: false },
    },
    {
      path: '/teams/:id',
      name: 'Team',
      component: TeamPage,
      meta: { withBUDropdown: false },
    },
    {
      path: '/rooms/:id',
      name: 'Room',
      component: RoomPage,
      meta: { withBUDropdown: false },
    },
    {
      path: '/edit',
      name: 'Edit Page',
      redirect: '/edit/employee',
      component: EditPage,
      children: [
        {
          name: 'Employee Create',
          path: 'people',
          component: EmployeeCreate,
          meta: { withBUDropdown: true },
        },
        {
          name: 'Employee Edit',
          path: 'people/:id',
          component: EmployeeEdit,
          meta: { withBUDropdown: false },
        },
        {
          name: 'Team Create',
          path: 'teams',
          component: TeamCreate,
          meta: { withBUDropdown: true },
        },
        {
          name: 'Team Edit',
          path: 'teams/:id',
          component: TeamEdit,
          meta: { withBUDropdown: false },
        },
        {
          name: 'Product Create',
          path: 'products',
          component: ProductCreate,
          meta: { withBUDropdown: true },
        },
        {
          name: 'Product Edit',
          path: 'products/:id',
          component: ProductEdit,
          meta: { withBUDropdown: false },
        },
        {
          name: 'Room Create',
          path: 'rooms',
          component: RoomCreate,
          meta: { withBUDropdown: false },
        },
        {
          name: 'Room Edit',
          path: 'rooms/:id',
          component: RoomEdit,
          meta: { withBUDropdown: false },
        },
        {
          name: 'Skill Create',
          path: 'skills',
          component: SkillCreate,
          meta: { withBUDropdown: true },
        },
        {
          name: 'Skill Edit',
          path: 'skills/:id',
          component: SkillEdit,
          meta: { withBUDropdown: false },
        },
        {
          name: 'Service Create',
          path: 'services',
          component: ServiceCreate,
          meta: { withBUDropdown: true },
        },
        {
          name: 'Service Edit',
          path: 'services/:id',
          component: ServiceEdit,
          meta: { withBUDropdown: false },
        },
      ],
    },
    {
      path: '/authentication/error',
      component: AuthenticationError,
    },
  ],
});
