<template>
  <ValidationProvider tag="div" :rules="rules" :name="name" v-slot="{ errors }">
    <textarea
      :class="{ invalid: errors[0] }"
      rows="5"
      @input="$emit('input', $event.target.value)"
      :value="value"
      type="text"
      :name="name"
      :placeholder="placeholder"
    ></textarea>
    <div :class="{ show: errors[0] }" class="errors">{{ errors[0] }}</div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'TextArea',
  components: {
    ValidationProvider,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
textarea {
  border-radius: $radius-small;
  border: 1px solid $light-grey;
  width: 100%;
  padding: 4px;
  &:focus {
    border: 1px solid $spark-orange;
    border-radius: $radius-small;
  }
  &.invalid {
    border: 1px solid $spark-orange;
  }
}
.errors {
  display: none;
  &.show {
    display: block;
    color: $spark-orange;
    margin-top: 5px;
  }
}
</style>
