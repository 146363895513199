import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    query: '',
    lowercase_query: '',
    employee_filter: [],
    selected_business_unit: '',
  },
  mutations: {
    SET_QUERY: (state, query) => {
      state.query = query;
      state.lowercase_query = query.toLowerCase();
    },
    ADD_EMPLOYEE_FILTER: (state, { tagId, isBusinessUnit }) => {
      if (isBusinessUnit) {
        state.employee_filter = state.employee_filter.filter(
          (id) => id !== state.selected_business_unit
        );
        state.selected_business_unit = tagId;
      }
      state.employee_filter.push(tagId);
    },
    REMOVE_EMPLOYEE_FILTER: (state, tagId) => {
      state.employee_filter = state.employee_filter.filter(
        (id) => id !== tagId
      );
      if (state.selected_business_unit === tagId) {
        state.selected_business_unit = '';
      }
    },
  },
});

export default store;
