<template>
  <ValidationProvider tag="div" :rules="rules" :name="name" v-slot="{ errors }">
    <input
      :class="[{ invalid: errors[0] }, classes]"
      @input="$emit('input', $event.target.checked)"
      :value="value"
      :checked="value"
      :name="name"
      :id="name"
      :placeholder="placeholder"
      type="checkbox"
    />
    <div :class="{ show: errors[0] }" class="errors">{{ errors[0] }}</div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'CheckBox',
  components: {
    ValidationProvider,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped lang="scss">
input[type='checkbox']:checked {
  filter: hue-rotate(150deg) brightness(1.7); /* To change the color of the checkbox to spark orange */
  scale: 1.5; /* To increase the size of the checkbox */
}
.errors {
  display: none;
  &.show {
    display: block;
    color: $spark-orange;
    margin-top: 5px;
  }
}
</style>
