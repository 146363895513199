<template>
  <div class="container">
    <Card classes="container left">
      <h1>Create a product</h1>
      <ProductForm v-bind.sync="product" @submitForm="submit" ref="productForm">
        <div slot="buttonGroup" class="button-group">
          <button
            class="button default"
            type="button"
            id="cancelButton"
            v-on:click="resetInterface()"
          >
            Cancel
          </button>
          <button type="submit" class="submit button" :disabled="submitting">
            Create
          </button>
        </div>
      </ProductForm>
    </Card>
    <Card classes="container right">
      <h1>Edit a product</h1>
      <ProductTagList />
    </Card>
  </div>
</template>

<script>
import ProductTagList from './ProductTagList';
import ProductForm from './ProductForm';
import Card from '../../basic/Card';
import { v4 as uuidv4 } from 'uuid';
import gql from 'graphql-tag';
import linksCrudMixin from '@/utils/linksCrudMixin';

const emptyProduct = {
  name: '',
  aliases: [],
  description: '',
  productowner: [],
  teams: [],
  links: [],
  businessUnits: [],
};

export default {
  components: {
    ProductTagList,
    ProductForm,
    Card,
  },
  mixins: [linksCrudMixin],
  data() {
    return {
      product: { ...emptyProduct },
      submitting: false,
    };
  },
  methods: {
    employeeList(selectedMembers) {
      return selectedMembers.map((entry) => entry.id);
    },
    teamList(selectedTeams) {
      return selectedTeams.map((entry) => entry.id);
    },
    resetInterface() {
      Object.assign(this.product, emptyProduct);
      this.$nextTick(() => {
        this.$refs.productForm.$refs.form?.reset();
      });
    },
    async createProduct() {
      const createdLinks = await this.createOrUpdateLinks(this.product.links);
      const data = {
        product: {
          id: uuidv4(),
          name: this.product.name,
          description: this.product.description,
          aliases: this.product.aliases,
        },
        relations: {
          productOwnerIds: this.product.productowner.map((entry) => entry.id),
          teamIds: this.product.teams.map((entry) => entry.id),
          linkIds: createdLinks,
          businessUnitIds: this.product.businessUnits.map((entry) => entry.id),
        },
      };
      await this.$apollo.mutate({
        mutation: gql`
          mutation($data: CreateAndUpdateProductInput!) {
            CreateAndUpdateProduct(data: $data) {
              id
            }
          }
        `,
        variables: {
          data,
        },
      });
      this.$toasted.global.update_toaster({
        message: `You have successfully created ${this.product.name}`,
      });
      await this.$apollo.getClient().resetStore();
      this.resetInterface();
    },
    async submit() {
      this.submitting = true;
      try {
        await this.createProduct();
        this.resetInterface();
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin-top: 0;
}
</style>
