<template>
  <div class="scroll-container">
    <div class="search-bar-header">
      <SearchBar v-model="query" />
    </div>
    <CardGrid>
      <Card
        classes="list-item"
        v-for="service of currentServices"
        :key="service.id"
      >
        <div class="header">
          <h1 class="name">{{ service.name }}</h1>
          <router-link :to="{ name: 'Service', params: { id: service.id } }">
            <More color="#b4c4cb" />
          </router-link>
        </div>
        <Bar />
        <div class="content">
          <div class="category" v-if="service.description">
            <MarkDown :source="service.description" />
          </div>
          <TagsContainer
            v-if="service.superusers"
            :tags="service.superusers"
            type="person"
            route="Employee"
          />
          <TagsContainer
            v-if="service.businessUnits.length > 0"
            :tags="service.businessUnits"
            type="business-unit"
          />
        </div>
      </Card>
    </CardGrid>
  </div>
</template>

<script>
import Bar from '../basic/Bar';
import More from '../basic/icons/More';
import Card from '../basic/Card';
import CardGrid from '../basic/CardGrid';
import SearchBar from '../basic/searchbar/SearchBar';
import TagsContainer from '../basic/tags/TagsContainer';
import gql from 'graphql-tag';
import { mapState } from 'vuex';
import MarkDown from '../basic/MarkDown';

export default {
  name: 'ServiceList',
  components: {
    Bar,
    Card,
    TagsContainer,
    More,
    SearchBar,
    CardGrid,
    MarkDown,
  },
  data() {
    return {
      services: [],
      query: '',
    };
  },
  computed: {
    ...mapState(['selected_business_unit']),
    currentServices() {
      return this.services.filter((service) => {
        return (
          (this.selected_business_unit === '' ||
            service.businessUnits.some(
              (bu) => this.selected_business_unit === bu.id
            )) &&
          service.name.toLowerCase().includes(this.query.toLowerCase())
        );
      });
    },
  },
  apollo: {
    services: {
      query: gql`
        query allServices {
          services {
            id
            name
            description
            superusers {
              id
              name
            }
            businessUnits {
              id
              name
            }
          }
        }
      `,
      update(data) {
        return this.sortArrayCaseInsensitive(data.services);
      },
    },
  },
};
</script>
