<template>
  <span>{{ count > 1 || count === 0 ? multiple : singular }}</span>
</template>

<script>
export default {
  name: 'Pluralize',
  props: {
    singular: String,
    multiple: String,
    count: Number,
  },
};
</script>
