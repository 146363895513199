export default {
  methods: {
    sortArrayCaseInsensitive(array) {
      return array === undefined
        ? undefined
        : [...array].sort(function (a, b) {
            return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
          });
    },
  },
};
