<template>
  <button class="filtertag" @click="updateFilter(id, type)">
    <Tag :title="title" :isActive="isSelected" :type="type" />
  </button>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Tag from './Tag';

export default {
  name: 'FilterTag',
  components: {
    Tag,
  },
  props: {
    title: String,
    id: String,
    type: String,
  },
  computed: {
    ...mapState(['employee_filter']),
    isSelected() {
      return this.employee_filter.includes(this.id);
    },
  },
  methods: {
    ...mapMutations(['ADD_EMPLOYEE_FILTER', 'REMOVE_EMPLOYEE_FILTER']),
    updateFilter(id, type) {
      if (this.employee_filter.includes(id)) {
        this.REMOVE_EMPLOYEE_FILTER(id);
      } else {
        this.ADD_EMPLOYEE_FILTER({
          tagId: id,
          isBusinessUnit: type === 'business-unit',
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.filtertag {
  display: flex;
  flex: 1 0 auto;
  cursor: pointer;
  max-width: 100%;
}
</style>
