<template>
  <div class="card-grid">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CardGrid',
};
</script>

<style scoped lang="scss">
.card-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  max-height: 100%;
  overflow-y: scroll;
  padding: 0 40px 40px 40px;
  @media (max-width: $mobile) {
    padding: 0 20px 30px 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
</style>
