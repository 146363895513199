<template>
  <button class="search-clear icon" v-on:click="$emit('clearsearch')">
    <Close color="#023A4F" />
  </button>
</template>

<script>
import Close from '../icons/Close';
export default {
  name: 'SearchClear',
  components: {
    Close,
  },
};
</script>
<style lang="scss" scoped>
.search-clear {
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
}
</style>
