<template>
  <div class="container">
    <Modal
      v-if="confirmModal"
      :name="service.name"
      @confirm="deleteService"
      @cancel="cancelDelete"
    />
    <Card>
      <h1>Edit {{ service.name }}</h1>
      <ServiceForm v-bind.sync="service" @submitForm="submit">
        <div slot="buttonGroup" class="button-group">
          <button class="button default" type="button" v-on:click="cancel()">
            Cancel
          </button>
          <button
            class="delete button"
            type="button"
            v-on:click="confirmDelete()"
          >
            Delete
          </button>
          <button class="submit button" type="submit">Save</button>
        </div>
      </ServiceForm>
    </Card>
  </div>
</template>

<script>
import ServiceForm from './ServiceForm';
import Modal from '../../basic/Modal';
import Card from '../../basic/Card';
import gql from 'graphql-tag';
import linksCrudMixin from '@/utils/linksCrudMixin';

export default {
  components: {
    Modal,
    Card,
    ServiceForm,
  },
  mixins: [linksCrudMixin],
  data() {
    return {
      service: {
        id: '',
        name: '',
        description: '',
        superusers: [],
        links: [],
        businessUnits: [],
      },
      confirmModal: false,
    };
  },
  apollo: {
    service: {
      query: gql`
        query($serviceId: ID!) {
          services(where: { id: $serviceId }) {
            id
            name
            description
            superusers {
              id
              name
            }
            businessUnits {
              id
              name
            }
            links {
              id
              displayname
              type
              url
            }
          }
        }
      `,
      variables() {
        return {
          serviceId: this.$route.params.id,
        };
      },
      update(data) {
        return data.services[0];
      },
    },
  },
  methods: {
    confirmDelete() {
      this.confirmModal = true;
    },
    cancelDelete() {
      this.confirmModal = false;
    },
    async deleteService() {
      const name = this.service.name;
      await this.$apollo.mutate({
        mutation: gql`
          mutation($id: ID!) {
            deleteServices(where: { id: $id }) {
              nodesDeleted
            }
          }
        `,
        variables: {
          id: this.service.id,
        },
      });
      await this.$apollo.getClient().clearStore();
      this.$toasted.global.update_toaster({
        message: `You have successfully removed ${name}`,
      });
      this.$router.replace({ name: 'Services' });
    },
    async updateService() {
      const createdLinks = await this.createOrUpdateLinks(this.service.links);
      const data = {
        service: {
          id: this.service.id,
          name: this.service.name,
          description: this.service.description,
        },
        relations: {
          superUserIds: this.service.superusers.map((entry) => entry.id),
          linkIds: createdLinks,
          businessUnitIds: this.service.businessUnits.map((entry) => entry.id),
        },
      };
      await this.$apollo.mutate({
        mutation: gql`
          mutation($data: CreateAndUpdateServiceInput!) {
            CreateAndUpdateService(data: $data) {
              id
            }
          }
        `,
        variables: {
          data,
        },
      });
      await this.$apollo.getClient().clearStore();
      this.$toasted.global.update_toaster({
        message: `You have successfully updated ${this.service.name}`,
      });
    },
    async submit() {
      await this.updateService();
      this.$router.back();
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>
