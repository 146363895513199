<template>
  <ValidationProvider
    name="business unit"
    :rules="required"
    v-slot="{ errors }"
  >
    <div class="flex-form" :class="{ with_errors: errors[0] }">
      <label
        ><Pluralize
          singular="Business unit"
          multiple="Business units"
          :count="value.length"
      /></label>
      <multiselect
        class="multi-select business-unit"
        ref="businessunitselector"
        :loading="loading()"
        :multiple="true"
        :taggable="false"
        :hide-selected="true"
        :value="sortedValue"
        label="name"
        track-by="id"
        :options="options"
        placeholder="Select a business unit"
        @input="handleInput"
      />
    </div>
    <div :class="{ show: errors[0] }" class="flex-form errors">
      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import gql from 'graphql-tag';
import Pluralize from '../../basic/Pluralize';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'BusinessUnitSelector',
  components: {
    Pluralize,
    ValidationProvider,
  },
  props: {
    value: {
      type: Array,
      default: () => [{}],
    },
    required: {
      type: String,
      default: 'required',
    },
  },
  computed: {
    sortedValue() {
      return this.sortArrayCaseInsensitive(this.value);
    },
  },
  data() {
    return {
      loadingTasks: 0,
      options: [],
    };
  },
  methods: {
    handleInput(input) {
      this.$emit('input', input);
    },
    loading() {
      return this.loadingTasks !== 0;
    },
    async loadTask(task) {
      this.loadingTasks++;
      try {
        const result = await task();
        return result;
      } finally {
        this.loadingTasks--;
      }
    },
  },
  apollo: {
    options: {
      query: gql`
        query {
          businessUnits(options: { sort: [{ name: ASC }] }) {
            id
            name
          }
        }
      `,
      update({ businessUnits }) {
        return this.sortArrayCaseInsensitive(businessUnits);
      },
    },
  },
};
</script>
