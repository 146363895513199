<template>
  <div class="person">
    <div
      :id="tippyId"
      class="profile-picture"
      v-bind:title="employee.name"
      slot="reference"
    >
      <ProfilePicture shouldHover :employee="employee" />
    </div>
    <div ref="popover" class="popover-template">
      <div class="pop-over__header">
        <h1 class="name">{{ employee.name }}</h1>
        <router-link
          :to="{ name: 'Employee', params: { id: employee.id } }"
          class="more"
        >
          <More color="#b4c4cb" />
        </router-link>
      </div>
      <Bar />
      <div class="pop-over__content tags-container">
        <Tag
          v-for="product in sortedProducts"
          :key="product.id"
          type="product"
          v-bind:title="product.name + ' (product owner)'"
        ></Tag>
        <Tag
          v-for="team in sortedTeams"
          :key="team.id"
          type="team"
          v-bind:title="team.name"
        ></Tag>
        <Tag
          v-for="skill in sortedSkills"
          :key="skill.id"
          type="skill"
          v-bind:title="skill.name"
        ></Tag>
        <Tag
          v-for="businessunit in sortedBusinessUnits"
          :key="businessunit.id"
          type="business-unit"
          v-bind:title="businessunit.name"
        ></Tag>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '../basic/tags/Tag';
import Bar from '../basic/Bar';
import More from '../basic/icons/More';
import ProfilePicture from './ProfilePicture';
import tippy, { roundArrow } from 'tippy.js';
import 'tippy.js/dist/svg-arrow.css';
export default {
  name: 'Person',
  components: {
    Tag,
    More,
    ProfilePicture,
    Bar,
  },
  props: {
    employee: Object,
  },
  mounted() {
    const element = document.querySelector('.employee-container');
    const template = this.$refs.popover;
    tippy(`#${this.tippyId}`, {
      content: template.innerHTML,
      allowHTML: true,
      trigger: 'click',
      arrow: roundArrow,
      animation: false,
      interactive: true,
      maxWidth: 'none',
      placement: 'auto',
      zIndex: 2,
      popperOptions: {
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: element,
              padding: { top: 60, right: 8, left: 8, bottom: 8 },
            },
          },
        ],
      },
    });
  },
  computed: {
    tippyId() {
      return `id${this.employee.id.replace(/-/g, '')}`;
    },
    sortedTeams() {
      return this.sortArrayCaseInsensitive(this.employee.teams);
    },
    sortedSkills() {
      return this.sortArrayCaseInsensitive(this.employee.skills);
    },
    sortedProducts() {
      return this.sortArrayCaseInsensitive(this.employee.productowner);
    },
    sortedBusinessUnits() {
      return this.sortArrayCaseInsensitive(this.employee.businessUnits);
    },
  },
};
</script>

<style scoped lang="scss">
.person {
  display: flex;
  justify-content: center;
  position: relative;
  .profile-picture {
    width: 120px;
    height: 120px;
  }
}
.name {
  font-size: $font-size-large;
  margin-top: 0;
  display: inline-block;
  width: 90%;
}
.more {
  float: right;
}
.pop-over__content {
  margin-top: 20px;
  &.tags-container {
    margin-left: -4px;
    margin-right: -4px;
  }
}
.popover-template {
  display: none;
}
</style>
