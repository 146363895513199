<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.5303 13.116C15.2374 13.4089 14.7626 13.4089 14.4697 13.116L10.1768 8.82313C10.0791 8.7255 9.92086 8.7255 9.82322 8.82313L5.53033 13.116C5.23744 13.4089 4.76256 13.4089 4.46967 13.116C4.17678 12.8231 4.17678 12.3483 4.46967 12.0554L8.76256 7.76247C9.44598 7.07905 10.554 7.07905 11.2374 7.76247L15.5303 12.0554C15.8232 12.3483 15.8232 12.8231 15.5303 13.116Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'Arrow',
  props: {
    color: {
      default: 'white',
      type: String,
    },
  },
};
</script>
