<template>
  <div class="tags">
    <Tag
      v-for="tag in sortedTags"
      :key="tag.id"
      :type="type"
      :id="tag.id"
      :title="tag.name"
      :href="
        route === undefined
          ? undefined
          : $router.resolve({ name: route, params: { id: tag.id } }).href
      "
    ></Tag>
  </div>
</template>

<script>
import Tag from './Tag';

export default {
  components: {
    Tag,
  },
  props: {
    tags: Array,
    type: String,
    route: String,
  },
  computed: {
    sortedTags() {
      return this.sortArrayCaseInsensitive(this.tags);
    },
  },
};
</script>

<style lang="scss" scoped>
.tags {
  margin-top: 20px;
  margin-left: -4px;
  margin-right: -4px;
}
</style>
