<template>
  <div class="circle" :class="{ hover: shouldHover, mobile: isMobile }">
    <img width="120" height="120" loading="lazy" alt v-bind:src="url" />
  </div>
</template>

<script>
const md5 = require('md5');

export default {
  name: 'ProfilePicture',
  props: {
    employee: Object,
    shouldHover: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      url: '',
    };
  },
  watch: {
    employee: {
      immediate: true,
      handler() {
        if (!this.employee.workplaceProfile.picture) {
          return null;
        }
        if (this.employee.workplaceProfile.is_silhouette === false) {
          this.url = this.employee.workplaceProfile.picture;
        } else {
          fetch(
            `https://www.gravatar.com/avatar/${md5(this.employee.mail)}?d=404`,
            {
              method: 'HEAD',
            }
          ).then((response) => {
            if (response.status === 404) {
              this.url = this.employee.workplaceProfile.picture;
            } else {
              this.url = `https://www.gravatar.com/avatar/${md5(
                this.employee.mail
              )}`;
            }
          });
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.circle {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: $radius-round;
  box-shadow: $box-shadow-emp;
  display: inline-block;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
  }
  &.hover {
    cursor: pointer;
    &:hover {
      box-shadow: $box-shadow-hover;
    }
  }
  &.mobile {
    width: 100%;
    align-self: center;
    height: auto;
    display: flex;
    max-width: 120px;
    img {
      max-width: 120px;
    }
  }
}
</style>
