<template>
  <button class="closetag" @click="removeTag(id)">
    <Tag :title="title" isActive isClosable />
  </button>
</template>

<script>
import { mapMutations } from 'vuex';
import Tag from './Tag';

export default {
  name: 'ClosableTag',
  components: {
    Tag,
  },
  props: {
    title: String,
    id: String,
    type: String,
  },
  methods: {
    ...mapMutations(['REMOVE_EMPLOYEE_FILTER']),
    removeTag(id) {
      this.REMOVE_EMPLOYEE_FILTER(id);
    },
  },
};
</script>
<style scoped lang="scss">
.closetag {
  display: flex;
  flex: 1 0 auto;
  cursor: pointer;
}
</style>
