<template>
  <div>
    <SearchableTagsContainer
      :tags="filteredEmployees"
      type="person"
      :route="'Employee Edit'"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';
import SearchableTagsContainer from '../../basic/tags/SearchableTagsContainer';
import EmployeeSelectorVue from '../selectors/EmployeeSelector.vue';

export default {
  name: 'employee-list',

  components: {
    SearchableTagsContainer,
  },
  data() {
    return {
      employees: [],
    };
  },
  computed: {
    ...mapState(['selected_business_unit']),
    filteredEmployees() {
      return this.employees.filter((employee) => {
        return (
          this.selected_business_unit === '' ||
          employee.businessUnits.some(
            (bu) => this.selected_business_unit === bu.id
          )
        );
      });
    },
  },

  apollo: {
    employees: {
      query: gql`
        query {
          employees {
            id
            name
            businessUnits {
              id
            }
          }
        }
      `,
      update({ employees }) {
        return this.sortArrayCaseInsensitive(employees);
      },
    },
  },
};
</script>
