<template>
  <div>
    <div class="nav">
      <router-link tag="div" :to="{ name: 'Employee Create' }" class="nav-elem">
        <a>Employee</a>
      </router-link>
      <router-link tag="div" :to="{ name: 'Team Create' }" class="nav-elem">
        <a>Team</a>
      </router-link>
      <router-link tag="div" :to="{ name: 'Product Create' }" class="nav-elem">
        <a>Product</a>
      </router-link>
      <router-link tag="div" :to="{ name: 'Skill Create' }" class="nav-elem">
        <a>Skill</a>
      </router-link>
      <router-link tag="div" :to="{ name: 'Service Create' }" class="nav-elem">
        <a>Service</a>
      </router-link>
      <router-link tag="div" :to="{ name: 'Room Create' }" class="nav-elem">
        <a>Room</a>
      </router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'EditPage',
};
</script>
<style scoped lang="scss">
.nav {
  background-color: white;
  box-shadow: $box-shadow-card;
  width: 100%;
  a {
    color: $heritage-blue;
    display: block;
    padding: 15px 0 5px 0;
  }
}
</style>
