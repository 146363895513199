<template>
  <div class="skill-list">
    <AccordionItem shouldOpen :title="`Skills (${filteredSkills.length})`">
      <FilterList type="skill" :tags="filteredSkills" />
    </AccordionItem>
  </div>
</template>

<script>
import FilterList from './FilterList';
import AccordionItem from './accordion/AccordionItem';
import gql from 'graphql-tag';

export default {
  components: {
    FilterList,
    AccordionItem,
  },
  props: {
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      skills: [],
    };
  },
  computed: {
    filteredSkills() {
      return this.skills.filter((skill) =>
        skill.name.toLowerCase().includes(this.query.toLowerCase())
      );
    },
  },

  apollo: {
    skills: {
      query: gql`
        query skills {
          skills {
            id
            name
          }
        }
      `,
      update({ skills }) {
        return this.sortArrayCaseInsensitive(skills);
      },
    },
  },
};
</script>
