<template>
  <div class="type__selector">
    <multiselect
      :value="type"
      :options="logos"
      :multiple="false"
      :searchable="false"
      :showPointer="false"
      :showLabels="false"
      @select="dispatchAction"
    >
      <template slot="singleLabel" slot-scope="{ option }">
        <img class="option__image" :src="source" :alt="option" />
      </template>

      <template slot="option" slot-scope="{ option }">
        <img class="option__image" :src="getLogo(option)" :alt="option" />
      </template>
    </multiselect>
  </div>
</template>

<script>
export default {
  name: 'LinkIconDropdown',
  model: {
    prop: 'type',
  },
  props: {
    type: String,
  },
  data() {
    return {
      logos: [
        'Nedap',
        'Github',
        'Slack',
        'Slite',
        'Workplace',
        'DataDog',
        'NewRelic',
        'Rollbar',
        'ExternalLink',
      ],
    };
  },
  computed: {
    source() {
      return this.getLogo(this.type);
    },
  },
  methods: {
    dispatchAction(newType) {
      this.$emit('input', newType);
    },
    getLogo(type) {
      return require(`@/assets/Logo${type}.svg`);
    },
  },
};
</script>
<style>
.link-icon {
  width: 32px;
}
.option__image {
  width: 32px;
  height: 32px;
}
</style>
