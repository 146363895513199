<template>
  <div class="container">
    <h1>Error while logging in</h1>
    <p>OKTA responded with the following error:</p>
    <blockquote>
      <p>
        {{ this.$route.query.message }}
      </p>
    </blockquote>
  </div>
</template>

<script>
export default {
  name: 'AuthenticationError',
};
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  height: calc(100vh - #{$menu-height});
  position: relative;
  overflow-y: scroll;
  padding: 30px;
}
</style>
