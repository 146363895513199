<template>
  <button class="button" :class="classes" @click="back()">{{ title }}</button>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    title: {
      type: String,
      default: 'Back',
    },
    classes: {
      type: String,
      default: 'default',
    },
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>
