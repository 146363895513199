<template>
  <div id="app">
    <vue-headful title="Nedap Family" />
    <div class="main-header">
      <div class="menu-bar">
        <div class="left">
          <AboutNavigator />
          <BusinessUnitDropdown v-show="withBUDropdown" />
        </div>
        <Navigation />
      </div>
    </div>
    <router-view v-if="hasLoaded"></router-view>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import store from './store/store.js';
import router from './router/index';
import gql from 'graphql-tag';
import vueHeadful from 'vue-headful';
import AboutNavigator from './components/AboutNavigator';
import Navigation from './components/Navigation';
import BusinessUnitDropdown from './components/BusinessUnitDropdown';
import { mapMutations } from 'vuex';
import './assets/css/index.scss';
export default {
  name: 'app',
  store,
  router,
  components: {
    Navigation,
    AboutNavigator,
    BusinessUnitDropdown,
    'vue-headful': vueHeadful,
  },
  data() {
    return {
      hasLoaded: false,
    };
  },
  computed: {
    withBUDropdown() {
      return this.$route.meta.withBUDropdown;
    },
  },
  methods: {
    ...mapMutations(['ADD_EMPLOYEE_FILTER']),
  },
  async created() {
    try {
      let response = await this.$apollo.query({
        query: gql`
          query {
            me {
              employee {
                businessUnits {
                  name
                }
              }
            }
          }
        `,
      });
      if (!response.data.me.employee) {
        console.warn(
          'Me is not an employee, not applying business unit filter...'
        );
        return;
      }
      response = await this.$apollo.query({
        query: gql`
          query($name: String!) {
            businessUnits(where: { name: $name }) {
              id
            }
          }
        `,
        variables: {
          name: response.data.me.employee.businessUnits[0].name,
        },
      });

      const id = response.data.businessUnits[0].id;
      this.ADD_EMPLOYEE_FILTER({ tagId: id, isBusinessUnit: true });
    } catch (error) {
      console.log(`Could not get BU because of ${error}`);
    } finally {
      this.hasLoaded = true;
    }
  },
};
</script>

<style scoped lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $font-color;
  .main-header {
    position: sticky;
    top: 0;
    z-index: $menu-bar-index;
    .menu-bar {
      width: 100%;
      height: $menu-height;
      left: 0;
      top: 0;
      padding-top: 15px;
      background: $heritage-blue;
      display: flex;
      justify-content: space-between;
      position: relative;
      padding-left: 20px;
      padding-right: 20px;
      .left {
        display: inline-flex;
      }
    }
  }
}
</style>
