<template>
  <div class="bar"></div>
</template>

<script>
export default {
  name: 'Bar',
};
</script>
<style lang="scss" scoped>
.bar {
  width: 40px;
  background-color: $spark-orange;
  height: 4px;
}
</style>
