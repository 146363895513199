<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <TextInputWithLabel
        rules="required"
        name="name"
        label="Name"
        :value="name"
        @input="$emit('update:name', $event)"
      />
      <TextAreaWithLabel
        label="Description"
        name="team-description"
        :value="description"
        @input="$emit('update:description', $event)"
      />
      <SingleTeamSelector
        name="parent-team"
        :value="parentTeam"
        @input="$emit('update:parentTeam', $event)"
        placeholder="Select a parent team"
      >
        Parent team
      </SingleTeamSelector>
      <EmployeeSelector
        :value="members"
        @input="$emit('update:members', $event)"
        placeholder="Select a member"
      >
        <Pluralize
          singular="Member"
          multiple="Members"
          :count="members.length"
        />
      </EmployeeSelector>
      <ProductSelector
        :value="products"
        @input="$emit('update:products', $event)"
      >
        <Pluralize
          singular="Product"
          multiple="Products"
          :count="products.length"
        />
      </ProductSelector>
      <BusinessUnitSelector
        :value="businessUnits"
        @input="$emit('update:businessUnits', $event)"
      />
      <Links
        @deleteLink="removeLinkFromList(links, $event)"
        :links="links"
        :value="links"
        @input="$emit('update:links', $event)"
      />
      <button
        class="button default"
        @click="addEmptyLinkToList(links)"
        type="button"
      >
        Add Link
      </button>
      <slot name="buttonGroup" />
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import SingleTeamSelector from '../selectors/SingleTeamSelector';
import ProductSelector from '../selectors/ProductSelector';
import EmployeeSelector from '../selectors/EmployeeSelector';
import BusinessUnitSelector from '../selectors/BusinessUnitSelector';
import Pluralize from '../../basic/Pluralize';
import TextAreaWithLabel from '../../basic/formfields/TextAreaWithLabel';
import TextInputWithLabel from '../../basic/formfields/TextInputWithLabel';
import Links from '../../basic/formfields/Links';
import linksCrudMixin from '@/utils/linksCrudMixin';

export default {
  components: {
    ValidationObserver,
    SingleTeamSelector,
    EmployeeSelector,
    ProductSelector,
    BusinessUnitSelector,
    Pluralize,
    TextAreaWithLabel,
    TextInputWithLabel,
    Links,
  },
  mixins: [linksCrudMixin],
  props: [
    'name',
    'description',
    'parentTeam',
    'members',
    'products',
    'businessUnits',
    'links',
  ],
  methods: {
    submit() {
      this.$emit('submitForm');
    },
  },
};
</script>
