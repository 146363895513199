<template>
  <div class="container">
    <Modal
      v-if="confirmModal"
      :name="team.name"
      @confirm="deleteTeam"
      @cancel="cancelDelete"
    />
    <Card>
      <h1>Edit {{ team.name }}</h1>
      <TeamForm v-bind.sync="team" @submitForm="submit">
        <div slot="buttonGroup" class="button-group">
          <button class="button default" type="button" v-on:click="cancel()">
            Cancel
          </button>
          <button
            class="delete button"
            type="button"
            v-on:click="confirmDelete()"
          >
            Delete
          </button>
          <button class="submit button" type="submit">Save</button>
        </div>
      </TeamForm>
    </Card>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import Modal from '../../basic/Modal';
import Card from '../../basic/Card';
import TeamForm from './TeamForm';
import linksCrudMixin from '@/utils/linksCrudMixin';

export default {
  components: {
    Modal,
    Card,
    TeamForm,
  },
  mixins: [linksCrudMixin],
  data() {
    return {
      confirmModal: false,
      team: {
        id: '',
        name: '',
        description: '',
        parentTeam: null,
        members: [],
        products: [],
        links: [],
        businessUnits: [],
      },
    };
  },
  apollo: {
    team: {
      query: gql`
        query($teamId: ID!) {
          teams(where: { id: $teamId }) {
            id
            name
            description
            parentTeam {
              id
              name
            }
            members {
              id
              name
            }
            products {
              id
              name
            }
            links {
              id
              displayname
              type
              url
            }
            businessUnits {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          teamId: this.$route.params.id,
        };
      },
      update(data) {
        return data.teams[0];
      },
    },
  },
  methods: {
    confirmDelete() {
      this.confirmModal = true;
    },
    cancelDelete() {
      this.confirmModal = false;
    },
    async deleteTeam() {
      const name = this.team.name;
      await this.$apollo.mutate({
        mutation: gql`
          mutation($id: ID!) {
            deleteTeams(where: { id: $id }) {
              nodesDeleted
            }
          }
        `,
        variables: {
          id: this.team.id,
        },
      });
      await this.$apollo.getClient().clearStore();
      this.$toasted.global.update_toaster({
        message: `You have successfully deleted ${name}`,
      });
      this.$router.replace({ name: 'Teams' });
    },
    async updateTeam() {
      const createdLinks = await this.createOrUpdateLinks(this.team.links);
      const data = {
        team: {
          id: this.team.id,
          name: this.team.name,
          description: this.team.description,
        },
        relations: {
          memberIds: this.team.members.map((entry) => entry.id),
          productIds: this.team.products.map((entry) => entry.id),
          businessUnitIds: this.team.businessUnits.map((entry) => entry.id),
          linkIds: createdLinks,
          parentIds: this.team.parentTeam ? [this.team.parentTeam.id] : [],
        },
      };
      await this.$apollo.mutate({
        mutation: gql`
          mutation($data: CreateAndUpdateTeamInput!) {
            CreateAndUpdateTeam(data: $data) {
              id
            }
          }
        `,
        variables: {
          data,
        },
      });
      await this.$apollo.getClient().clearStore();
      this.$toasted.global.update_toaster({
        message: `You have successfully updated ${this.team.name}`,
      });
    },
    async submit() {
      await this.updateTeam();
      this.$router.back();
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>
