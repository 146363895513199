<template>
  <Card classes="full-width glance">
    <div class="photo-header">
      <ProfilePicture :employee="employee" />
    </div>
    <div class="name">
      <h1>{{ employee.name }}</h1>
      <Bar />
    </div>
    <div class="mail category">
      <div class="label">Mail</div>
      <div class="value">
        <a :href="`mailto:${employee.mail}`"
          ><img src="@/assets/LogoOutlook.svg" alt="Outlook logo" />
          {{ employee.mail }}</a
        >
      </div>
    </div>
    <div v-if="employee.githubUserName" class="github-user-name category">
      <div class="label">GitHub name</div>
      <div class="value">
        <a
          :href="`https://github.com/${employee.githubUserName}`"
          target="_blank"
          ><img src="@/assets/LogoGithub.svg" alt="GitHub logo" />{{
            employee.githubUserName
          }}</a
        >
      </div>
    </div>
    <TagsContainerWithLabel
      v-if="employee.teams.length > 0"
      label="Team"
      pluralLabel="Teams"
      :tags="employee.teams"
      type="team"
      route="Team"
    />
    <TagsContainerWithLabel
      v-if="employee.skills.length > 0"
      label="Skill"
      pluralLabel="Skills"
      :tags="employee.skills"
      type="skill"
    />
    <TagsContainerWithLabel
      v-if="employee.productowner.length > 0"
      label="Product owner of"
      pluralLabel="Product owner of"
      :tags="employee.productowner"
      type="product"
      route="Product"
    />
    <TagsContainerWithLabel
      v-if="employee.services.length > 0"
      label="Super user/ Admin of"
      pluralLabel="Super user/ Admin of"
      :tags="employee.services"
      type="service"
      route="Service"
    />
    <TagsContainerWithLabel
      label="Business unit"
      pluralLabel="Business units"
      :tags="employee.businessUnits"
      type="business-unit"
    />
    <div class="button-group">
      <button class="default button" v-on:click="edit(employee.id)">
        Edit
      </button>
      <BackButton title="OK" classes="submit" />
    </div>
  </Card>
</template>

<script>
import gql from 'graphql-tag';
import TagsContainerWithLabel from '../basic/tags/TagsContainerWithLabel';
import BackButton from '../basic/BackButton';
import Bar from '../basic/Bar';
import Card from '../basic/Card';
import ProfilePicture from './ProfilePicture';

export default {
  name: 'ProfilePage',
  components: {
    TagsContainerWithLabel,
    BackButton,
    Card,
    ProfilePicture,
    Bar,
  },
  data() {
    return {
      employee: {
        name: '',
        mail: '',
        githubUserName: '',
        productowner: [],
        workplaceProfile: {},
        teams: [],
        skills: [],
        services: [],
        businessUnits: [],
      },
    };
  },
  methods: {
    edit(id) {
      this.$router.push({ name: 'Employee Edit', params: { id: id } });
    },
  },
  apollo: {
    employee: {
      query: gql`
        query SearchEmployee($employeeId: ID!) {
          employees(where: { id: $employeeId }) {
            id
            name
            mail
            githubUserName
            workplaceProfile {
              picture
              is_silhouette
            }
            teams {
              id
              name
            }
            skills {
              id
              name
            }
            services {
              id
              name
            }
            productowner {
              id
              name
            }
            businessUnits {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          employeeId: this.$route.params.id,
        };
      },
      update(data) {
        return data.employees[0];
      },
    },
  },
};
</script>

<style scoped lang="scss">
.photo-header {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}
.github-user-name,
.mail {
  a {
    text-decoration: none;
    color: $heritage-blue;
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
      height: 1.5em;
    }
    &:hover {
      img {
        filter: invert(57%) sepia(98%) saturate(2602%) hue-rotate(335deg)
          brightness(100%) contrast(104%);
      }
    }
  }
}
</style>
