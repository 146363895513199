<template>
  <div class="product-list">
    <AccordionItem shouldOpen :title="`Products (${filteredProducts.length})`">
      <FilterList type="product" :tags="filteredProducts" />
    </AccordionItem>
  </div>
</template>

<script>
import FilterList from './FilterList';
import { mapState } from 'vuex';
import AccordionItem from './accordion/AccordionItem';
import gql from 'graphql-tag';

export default {
  components: {
    FilterList,
    AccordionItem,
  },
  props: {
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapState(['selected_business_unit']),
    filteredProducts() {
      return this.products.filter((product) => {
        const businessUnitOK =
          this.selected_business_unit === '' ||
          product.businessUnits.some(
            (bu) => bu.id === this.selected_business_unit
          );
        return (
          businessUnitOK &&
          product.name.toLowerCase().includes(this.query.toLowerCase())
        );
      });
    },
  },

  apollo: {
    products: {
      query: gql`
        query {
          products {
            id
            name
            businessUnits {
              id
            }
          }
        }
      `,
      update({ products }) {
        return this.sortArrayCaseInsensitive(products);
      },
    },
  },
};
</script>
