import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import App from './App.vue';
import { createProvider } from './vue-apollo';
import Toasted from 'vue-toasted';
import { extend } from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';
import sortMixin from './utils/sortMixin';

Vue.config.productionTip = false;
Vue.component('multiselect', Multiselect);

extend('max', {
  ...max,
  params: ['length'],
  message: '{_field_} is too long, max. {length} charachers',
});
extend('nedapmail', {
  validate: (email) => {
    return /^([a-zA-Z0-9_\-.]+)@nedap\.com$/.test(email);
  },
  message: 'The mail address should be a Nedap mail address',
});
extend('required', {
  ...required,
  message: 'Filling in a {_field_} is required',
});
extend('url', {
  validate: (url) => {
    return /^(http:\/\/|https:\/\/)\S+$/.test(url);
  },
  message: 'The url is not valid. It should start with https or http',
});

Vue.use(Toasted);
const options = {
  duration: 3000,
  theme: 'outline',
  className: 'family-toast',
  position: 'bottom-right',
};

Vue.toasted.register(
  'update_toaster',
  (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
      return 'It was successfull';
    }
    // if there is a message show it with the message
    return payload.message;
  },
  options
);

Vue.mixin(sortMixin);

new Vue({
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');
