<template>
  <button
    class="business-unit-dropdown"
    :class="{ opened: isOpen }"
    @click="toggleDropdown"
  >
    <div class="selected-unit">
      <span class="name">{{ selectedBusinessUnit.name }}</span>
      <span class="abbreviation">{{
        selectedBusinessUnit.abbreviation
          ? selectedBusinessUnit.abbreviation
          : selectedBusinessUnit.name
      }}</span>
      <div class="arrow"><Arrow color="#FFF" /></div>
    </div>
    <ul class="dropdown">
      <li
        @click="resetBusinessUnitSelector"
        :class="{ selected: selectedBusinessUnit.id === 1 }"
      >
        All business units (All)
      </li>
      <li
        v-for="businessUnit in businessUnits"
        :key="businessUnit.id"
        :class="{ selected: selectedBusinessUnit.id === businessUnit.id }"
        @click="setBusinessUnitSelector(businessUnit.id)"
      >
        {{ businessUnit.name }}
        <span v-if="businessUnit.abbreviation">
          ({{ businessUnit.abbreviation }})</span
        >
      </li>
    </ul>
  </button>
</template>

<script>
import gql from 'graphql-tag';
import Arrow from './basic/icons/Arrow';
import { mapMutations, mapState } from 'vuex';

export default {
  components: {
    Arrow,
  },
  data() {
    return {
      isOpen: false,
      businessUnits: [],
    };
  },
  computed: {
    ...mapState(['selected_business_unit']),
    selectedBusinessUnit() {
      return (
        this.businessUnits.find(
          (b) => b.id === this.selected_business_unit
        ) || { name: 'All business units', abbreviation: 'All', id: 1 }
      );
    },
  },
  methods: {
    ...mapMutations(['ADD_EMPLOYEE_FILTER', 'REMOVE_EMPLOYEE_FILTER']),
    setBusinessUnitSelector(id) {
      this.ADD_EMPLOYEE_FILTER({ tagId: id, isBusinessUnit: true });
    },
    resetBusinessUnitSelector() {
      this.REMOVE_EMPLOYEE_FILTER(this.selected_business_unit);
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
  },
  apollo: {
    businessUnits: {
      query: gql`
        query {
          businessUnits(options: { sort: [{ name: ASC }] }) {
            id
            name
            abbreviation
          }
        }
      `,
      update({ businessUnits }) {
        return businessUnits;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.business-unit-dropdown {
  margin-top: -15px;
  color: white;
  padding: 0;
  .selected-unit {
    padding: 15px 20px;
    height: $menu-height;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 220px;
    background-color: $heritage-blue-70;
    transition: background-color 0.2s;
    cursor: pointer;

    &:hover {
      background-color: $spark-orange;
    }

    .abbreviation {
      display: none;
    }
  }
  .arrow {
    transform: rotate(180deg);
    height: 20px;
    display: inline-block;
    transition: transform 0.5s;
  }
  .dropdown {
    list-style: none;
    top: -400px;
    background-color: $heritage-blue;
    padding: 0;
    margin: 0;
    position: absolute;
    transition: top 0.5s;
    z-index: -1;
    .selected {
      background-color: $heritage-blue-70;
    }
    li {
      padding: 10px 20px;
      cursor: pointer;
      &:hover {
        background-color: $spark-orange;
      }
    }
  }

  &.opened {
    .dropdown {
      top: $menu-height;
    }
    .arrow {
      transform: rotate(0deg);
    }
  }

  @media (max-width: $extra-small) {
    width: auto;
    .selected-unit {
      width: 100px;
      .abbreviation {
        display: inline;
      }
      .name {
        display: none;
      }
    }
  }
}
</style>
