<template>
  <ValidationProvider tag="div" :rules="rules" :name="name" v-slot="{ errors }">
    <input
      :class="[{ invalid: errors[0] }, classes]"
      @input="$emit('input', $event.target.valueAsNumber)"
      :value="value"
      :name="name"
      :placeholder="placeholder"
      type="number"
      min="0"
    />
    <div :class="{ show: errors[0] }" class="errors">{{ errors[0] }}</div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'NumberInput',
  components: {
    ValidationProvider,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped lang="scss">
input {
  height: 30px;
  border-radius: $radius-small;
  border: 1px solid $light-grey;
  width: 100%;
  padding: 4px;
  &:focus {
    border: 1px solid $spark-orange;
    border-radius: $radius-small;
  }
  &.invalid {
    border: 1px solid $spark-orange;
  }
  &.full-height {
    height: 100%;
  }
}
.errors {
  display: none;
  &.show {
    display: block;
    color: $spark-orange;
    margin-top: 5px;
  }
}
</style>
