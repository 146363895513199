<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <TextInputWithLabel
        rules="required"
        name="name"
        label="Name"
        :value="name"
        @input="$emit('update:name', $event)"
      />
      <TextInputWithLabel
        rules="required|nedapmail"
        name="mail address"
        label="Mail"
        :value="mail"
        @input="$emit('update:mail', $event)"
      />
      <div class="mail-info">
        <i>to import your Workplace photo</i>
      </div>
      <TextInputWithLabel
        name="githubUserName"
        label="Github username"
        :value="githubUserName"
        @input="$emit('update:githubUserName', $event)"
      />
      <TeamSelector :value="teams" @input="$emit('update:teams', $event)"
        ><Pluralize singular="Team" multiple="Teams" :count="teams.length"
      /></TeamSelector>
      <SkillSelector :value="skills" @input="$emit('update:skills', $event)"
        ><Pluralize singular="Skill" multiple="Skills" :count="skills.length"
      /></SkillSelector>
      <ProductSelector
        :value="productowner"
        @input="$emit('update:productowner', $event)"
        >Product owner of</ProductSelector
      >
      <ServiceSelector
        :value="services"
        @input="$emit('update:services', $event)"
        >Super user/admin of</ServiceSelector
      >
      <BusinessUnitSelector
        :value="businessUnits"
        @input="$emit('update:businessUnits', $event)"
      />
      <slot name="buttonGroup" />
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import TeamSelector from '../selectors/TeamSelector';
import SkillSelector from '../selectors/SkillSelector';
import ProductSelector from '../selectors/ProductSelector';
import ServiceSelector from '../selectors/ServiceSelector';
import BusinessUnitSelector from '../selectors/BusinessUnitSelector';
import Pluralize from '../../basic/Pluralize';
import TextInputWithLabel from '../../basic/formfields/TextInputWithLabel';

export default {
  components: {
    ValidationObserver,
    TeamSelector,
    BusinessUnitSelector,
    SkillSelector,
    ProductSelector,
    ServiceSelector,
    TextInputWithLabel,
    Pluralize,
  },
  props: [
    'name',
    'mail',
    'githubUserName',
    'teams',
    'skills',
    'productowner',
    'services',
    'businessUnits',
  ],
  methods: {
    submit() {
      this.$emit('submitForm');
    },
  },
};
</script>
<style lang="scss" scoped>
.mail-info {
  margin: -20px 0 20px 30%;
}
</style>
