<template>
  <div class="flex-form">
    <label><slot></slot></label>
    <multiselect
      class="multi-select person"
      ref="employeeselector"
      :loading="loading()"
      :multiple="true"
      :taggable="false"
      :hide-selected="true"
      :value="sortedValue"
      label="name"
      track-by="id"
      :options="options"
      :placeholder="placeholder"
      @input="handleInput"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'EmployeeSelector',
  props: {
    value: {
      type: Array,
      default: () => [{}],
    },
    placeholder: {
      type: String,
      default: 'Select a person',
    },
  },
  computed: {
    sortedValue() {
      return this.sortArrayCaseInsensitive(this.value);
    },
  },
  data() {
    return {
      loadingTasks: 0,
      options: [],
    };
  },
  methods: {
    handleInput(input) {
      this.$emit('input', input);
    },
    loading() {
      return this.loadingTasks !== 0;
    },
    async loadTask(task) {
      this.loadingTasks++;
      try {
        const result = await task();
        return result;
      } finally {
        this.loadingTasks--;
      }
    },
  },
  apollo: {
    options: {
      query: gql`
        query {
          employees {
            id
            name
          }
        }
      `,
      update({ employees }) {
        return this.sortArrayCaseInsensitive(employees);
      },
    },
  },
};
</script>
