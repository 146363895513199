<template>
  <div class="container">
    <Modal
      v-if="confirmModal"
      :name="product.name"
      @confirm="deleteProduct"
      @cancel="cancelDelete"
    />
    <Card>
      <h1>Edit {{ product.name }}</h1>
      <ProductForm v-bind.sync="product" @submitForm="submit">
        <div slot="buttonGroup" class="button-group">
          <button class="button default" type="button" v-on:click="cancel()">
            Cancel
          </button>
          <button
            class="delete button"
            type="button"
            v-on:click="confirmDelete()"
          >
            Delete
          </button>
          <button class="submit button" type="submit">Save</button>
        </div>
      </ProductForm>
    </Card>
  </div>
</template>

<script>
import ProductForm from './ProductForm';
import Modal from '../../basic/Modal';
import Card from '../../basic/Card';
import gql from 'graphql-tag';
import linksCrudMixin from '@/utils/linksCrudMixin';
export default {
  components: {
    Modal,
    Card,
    ProductForm,
  },
  mixins: [linksCrudMixin],
  data() {
    return {
      product: {
        id: null,
        name: '',
        aliases: [],
        description: '',
        productowner: [],
        teams: [],
        links: [],
        businessUnits: [],
      },
      confirmModal: false,
    };
  },
  apollo: {
    product: {
      query: gql`
        query($id: ID!) {
          products(where: { id: $id }) {
            id
            name
            aliases
            description
            productowner {
              id
              name
            }
            teams {
              id
              name
            }
            links {
              id
              displayname
              type
              url
            }
            businessUnits {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      update(data) {
        return data.products[0];
      },
    },
  },
  methods: {
    confirmDelete() {
      this.confirmModal = true;
    },
    cancelDelete() {
      this.confirmModal = false;
    },
    async deleteProduct() {
      const name = this.product.name;
      await this.$apollo.mutate({
        mutation: gql`
          mutation($id: ID!) {
            deleteProducts(where: { id: $id }) {
              nodesDeleted
            }
          }
        `,
        variables: {
          id: this.product.id,
        },
      });
      await this.$apollo.getClient().clearStore();
      this.$toasted.global.update_toaster({
        message: `You have successfully removed ${name}`,
      });
      this.$router.replace({ name: 'Products' });
    },
    async updateProduct() {
      const createdLinks = await this.createOrUpdateLinks(this.product.links);
      const data = {
        product: {
          id: this.product.id,
          name: this.product.name,
          description: this.product.description,
          aliases: this.product.aliases,
        },
        relations: {
          productOwnerIds: this.product.productowner.map((entry) => entry.id),
          teamIds: this.product.teams.map((entry) => entry.id),
          linkIds: createdLinks,
          businessUnitIds: this.product.businessUnits.map((entry) => entry.id),
        },
      };
      await this.$apollo.mutate({
        mutation: gql`
          mutation($data: CreateAndUpdateProductInput!) {
            CreateAndUpdateProduct(data: $data) {
              id
            }
          }
        `,
        variables: {
          data,
        },
      });
      await this.$apollo.getClient().clearStore();
      this.$toasted.global.update_toaster({
        message: `You have successfully updated ${this.product.name}`,
      });
    },
    async submit() {
      await this.updateProduct();
      this.$router.back();
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin-top: 0;
}
</style>
