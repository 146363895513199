<template>
  <div class="employee--grid">
    <Person
      v-for="employee in employees"
      :key="employee.id"
      :employee="employee"
    ></Person>
  </div>
</template>

<script>
import Person from './Person';

export default {
  name: 'EmployeeGrid',
  components: { Person },
  props: {
    employees: Array,
  },
};
</script>

<style lang="scss" scoped>
.employee--grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-template-rows: 120px;
  text-align: center;
  position: relative;
  padding: 0 40px 30px 40px;
  min-height: calc(100% - 70px);
}
@media (max-width: $mobile) {
  .employee--grid {
    display: none;
  }
}
</style>
