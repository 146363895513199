<template>
  <div class="container">
    <Card classes="container left">
      <h1>Create a skill</h1>
      <SkillForm v-bind.sync="skill" @submitForm="submit" ref="skillForm">
        <div slot="buttonGroup" class="button-group">
          <button
            class="button default"
            type="button"
            id="cancelButton"
            @click="resetInterface()"
          >
            Cancel
          </button>
          <button type="submit" class="submit button" :disabled="submitting">
            Create
          </button>
        </div>
      </SkillForm>
    </Card>
    <Card classes="container right">
      <h1>Edit a skill</h1>
      <SkillList />
    </Card>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import SkillList from './SkillList';
import { v4 as uuidv4 } from 'uuid';
import Card from '../../basic/Card';
import SkillForm from './SkillForm';

const emptySkill = {
  name: '',
  employees: [],
};

export default {
  components: {
    SkillList,
    Card,
    SkillForm,
  },
  data() {
    return {
      skill: { ...emptySkill },
      submitting: false,
    };
  },
  methods: {
    resetInterface() {
      Object.assign(this.skill, emptySkill);
      this.$nextTick(() => {
        this.$refs.skillForm.$refs.form?.reset();
      });
    },
    async createSkill() {
      const data = {
        skill: {
          id: uuidv4(),
          name: this.skill.name,
        },
        relations: {
          employeeIds: this.skill.employees.map((entry) => entry.id),
        },
      };

      await this.$apollo.mutate({
        mutation: gql`
          mutation($data: CreateAndUpdateSkillInput!) {
            CreateAndUpdateSkill(data: $data) {
              id
            }
          }
        `,
        variables: {
          data,
        },
      });
      this.$toasted.global.update_toaster({
        message: `You have successfully created ${this.skill.name}`,
      });
      await this.$apollo.getClient().resetStore();
    },
    async submit() {
      this.submitting = true;
      try {
        await this.createSkill();
        this.resetInterface();
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin-top: 0;
}
</style>
