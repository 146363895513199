<template>
  <div>
    <div class="header">
      <label><i>Click on a tag to edit</i></label>
      <SearchBar v-model="query" />
    </div>
    <TagsContainer :tags="filteredTags" :type="type" :route="route" />
  </div>
</template>

<script>
import SearchBar from '../../basic/searchbar/SearchBar';
import TagsContainer from './TagsContainer';

export default {
  components: {
    SearchBar,
    TagsContainer,
  },
  data() {
    return {
      query: '',
    };
  },
  props: {
    tags: Array,
    type: String,
    route: String,
  },
  computed: {
    filteredTags() {
      return this.tags.filter((tag) => {
        return tag.name.toLowerCase().includes(this.query.toLowerCase());
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  label {
    margin-right: 60px;
  }

  .search-bar {
    flex-grow: 1;
    display: inline-flex;
  }
}
.tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
}
</style>
