<template>
  <div v-html="content" />
</template>
<script>
import MarkdownIt from 'markdown-it';

export default {
  props: {
    source: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      md: null,
    };
  },
  computed: {
    content() {
      const src = this.source;
      return this.md?.render(src);
    },
  },
  created() {
    this.md = new MarkdownIt();
  },
};
</script>
