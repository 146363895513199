<template>
  <div class="container">
    <label :for="name">{{ label }}</label>
    <TextArea
      @input="$emit('input', $event)"
      :value="value"
      :rules="rules"
      :name="name"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import TextArea from './TextArea';

export default {
  name: 'TextAreaWithLabel',
  components: {
    TextArea,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  margin-bottom: 20px;
  label {
    width: 30%;
    padding-right: 15px;
    text-align: right;
  }
  & > div {
    width: 70%;
  }
}
</style>
